let UCSFConfig = window.UCSFConfig || {};
module.exports = {
    API: UCSFConfig && UCSFConfig.API ?
        // UCSFConfig.API : "https://api.ucsf.test2.redblink.net/",
        UCSFConfig.API : "https://ucsfdev.test2.redblink.net/",
        // UCSFConfig.API : "https://skintracker-be.ucsf.edu/",
    BaseUrl: "http://localhost:4000",
    userRoles: {
        ROLE_ADMIN: {
            url: "/dashboard/",
        },
        ROLE_PATIENT: {
            url: "/login/",
        },
    },
    userRoleTypes: {
        ROLE_ADMIN: {
            url: "/studies/",
        },
        ROLE_PATIENT: {
            url: "/login/",
        },
        ROLE_STUDYTEAM: {
            url: "/studies/",
        },
        ROLE_PHYSICIAN: {
            url: "/studies/",
        },
        ROLE_PI_USER: {
            url: "/studies/",
        },
        ROLE_SUPERADMIN: {
            url: "/user-list/",
        },
    },
    token: {
        expire: 0
    },
    timeUnits :[{ name: "Days", unit: "Days" }, { name: "Week", unit: "Week" }, { name: "Hours", unit: "Hours" }, { name: "Month", unit: "Month" }],
    systemUserRoles: [{ name: "STUDYTEAM", role: "STUDYTEAM" }, { name: "PHYSICIAN", role: "PHYSICIAN" }, { name: "PI_USER", role: "PI_USER" }],
    server: {
        type: "_test"
    },
    reviewStandard: {
        url: "https://ucsf.test2.redblink.net/review-table.html"
    },
    downloadCsv: {
        // Prod
        // url: "https://skintracker-be.ucsf.edu/api/download/adverseEvents/janssen/"
        // Dev
        url: "https://ucsfdev.test2.redblink.net/api/download/adverseEvents/janssen/",
    }
};