import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";

import * as MedicationActions from "../../redux/actions/medication-actions";
import "react-h5-audio-player/lib/styles.css";

import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Button,
  Form,
  Input,
  Comment,
  Tooltip,
  PageHeader,
} from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import { LabSvg } from "../shared/svg/labicon";
import { AuditOutlined } from "@ant-design/icons";
import { MedicationIconSvg } from "../shared/svg/medication";


const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const MedicationIcon = (props) => (
  <Icon component={MedicationIconSvg} {...props} />
);

const dateFormat = "Do MMM";
class Flares extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      formKey: 0,
      submitted: false,
      patientVoiceDiary: [],
      selectItemId: "",
      selectedCol: "",
      medication: undefined,
      medicationId: "",
      detailLoading: false,
      medicationList: undefined,
      medicationDetailData: undefined,
      flareList: undefined,
      flareTasks: undefined

    };
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
        //   loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    let patientId = this.props.match.params.id;
 
    await this.props.getPatientByIdNih(patientId);

    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      this.setState({ loading: false });
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
    let data = await this.props.listFlares(patientId);
    if (data && data.length > 0) {
        this.setState({
          flareList: data,
          loading: false,
        });
        let dateTask = this.convertToYMD(data && data[0].completeDate);
        let medData = await this.props.getFlareTasks(patientId,dateTask);
        if (medData) {
            this.setState({
                selectItemId: data[0].flareTaskName+"-"+this.convertToDate(data[0].completeDate),
                detailLoading: false,
                flareTasks: medData.data
              });
            }
    } else {
      this.setState({loading: false})
    }
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };
  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-progress/" + patientId);
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  convertToYMD(dateString) {
    // Create a Date object from the date string
    const date = new Date(dateString);
  
    // Extract year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
  
    // Format as YYYY-MM-DD
    return `${year}-${month}-${day}`;
  }

  async goToFlareTasks(item) {
    this.setState({ detailLoading: true });
    var patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId");
    let date = this.convertToYMD(item.completeDate);
    let medData = await this.props.getFlareTasks(patientId,date);
    if (medData) {
        this.setState({
            selectItemId: item.flareTaskName+"-"+this.convertToDate(item.completeDate),
            detailLoading: false,
            flareTasks: medData.data
          });
        }
    }

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  convertToDate(dateString) {
    // Create a Date object
    const date = new Date(dateString);
  
    // Format the date to MM/DD/YYYY
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  
    return formattedDate;
  }

  goToTask = (taskName, id, date) => {
    let taskDate = this.convertToYMD(date);
    var patientId = this.props.match.params.id;
    if (taskName === "Medication usage") {
        this.props.history.push("/flares-medication/" + patientId + "/" + id);
    } else 
    if (taskName === "Photographs") {
        this.props.history.push("/flare-photographs/" + patientId + "/" + taskDate);
    } else if (taskName === "Voice diary") {
        this.props.history.push("/flare-diary/" + patientId + "/" + taskDate);
    }else {
      this.props.history.push("/flare-tasks/" + patientId + "/" + id);
    }
  };

  goToNewMed = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/new-medication/" + patientId);
  }

  render() {

    let studyId = localStorage.getItem("studyId");
    const { submitted, selectItemId, selectedCol, medication, detailLoading, medicationList, medicationDetailData, flareList, flareTasks } =
      this.state;


    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <PageHeader
              className="site-header-title"
              title={
                this.state.data &&
                  this.state.data.title
              }
            ></PageHeader>
            <Spin spinning={submitted}>
              {!this.state.loading && (
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={6} xl={6}>
                    <div className="white-box user-section">
                      <div className="user-profile">
                        <Link to={"/patient-progress/" + this.props.match.params.id} >
                          <Avatar size={84}>
                            {this.props.patientData &&
                              this.props.patientData[0].firstName
                                .toUpperCase()
                                .charAt(0) +
                              this.props.patientData[0].lastName
                                .toUpperCase()
                                .charAt(0)}
                          </Avatar>
                        </Link>
                        <div className="profile-details">
                          <Link to={"/patient-progress/" + this.props.match.params.id} >
                            <h3>
                              {this.props.patientData &&
                                this.props.patientData[0].firstName +
                                " " +
                                this.props.patientData[0].lastName}
                            </h3>
                          </Link>
                          
                        </div>
                      </div>
                      <div className="user-details">
                        <List
                          style={{ marginTop: "40px" }}
                          itemLayout="horizontal"
                          dataSource={this.props.patientData}
                          renderItem={(patient) => (
                            <>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Study:
                                </Typography.Text>{" "}
                                {patient.title}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Email:
                                </Typography.Text>{" "}
                                {patient.email}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Date of Birth:
                                </Typography.Text>{" "}
                                {/* {patient.dateOfBirth
                                  ? moment(new Date(Number(patient.dateOfBirth))).format(
                                    "DD-MM-YYYY"
                                  )
                                  : "N/A"} */}
                                  {finalDate}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Age:
                                </Typography.Text>{" "}
                                {patient.age ? patient.age + " years" : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Phone Number:
                                </Typography.Text>{" "}
                                {"(" +
                                  patient.phoneCode +
                                  ") " +
                                  patient.phoneNumber}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Member Status:
                                </Typography.Text>{" "}
                                {patient.userScreeningStatus.replace("_", " ")}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Registered Date:
                                </Typography.Text>{" "}
                                {patient.createdDate
                                  ? moment(patient.createdDate)
                                    .format(dateFormathh)
                                  : "N/A"}
                              </List.Item>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={18} xl={18}>
                    <Card
                      title={"Flare Tasks"}
                      extra={
                        <Button onClick={() => this.goToPatientProgress()}>
                          Back
                        </Button>
                      }
                      style={{ marginBottom: "8px", padding: "0 16px" }}
                      className="patient-dairy-section"
                    >
                      <Row gutter={0}>
                        <Col xs={24} sm={12} md={8} xl={8}>
                          {/* Activity Tabs START */}
                          <div className="card-container">
                            <List
                              itemLayout="horizontal"
                              dataSource={flareList && flareList.sort((a, b) => new Date(b.completeDate) - new Date(a.completeDate))}
                              renderItem={(item) => (
                                <List.Item
                                    style={{cursor: "pointer"}}
                                    onClick={() => this.goToFlareTasks(item)}
                                    className={
                                        selectItemId === item.flareTaskName+"-"+this.convertToDate(item.completeDate)
                                        ? "selected-item-list flares"
                                        : "item-list flaresg"
                                    }
                                >
                                  <List.Item.Meta
                                    title={
                                      item.flareTaskName
                                        ? item.flareTaskName+"-"+this.convertToDate(item.completeDate) : ""
                                    }
                                  />
                                </List.Item>
                              )}
                            />
                          </div>
                          {/* /Activity Tabs END */}
                        </Col>
                        <Col xs={24} sm={12} md={16} xl={16}>
                          {/* Activity Content Section Start */}
                          <div className="dairy-content-section flares">
                            <div className="play-icon-container"></div>
                                <Spin spinning={detailLoading}>
                                    <List
                                        itemLayout="horizontal"
                                        className="screening-ques"
                                        dataSource={flareTasks}
                                        renderItem={(item, index) => (
                                        <>
                                        <List.Item
                                            key={index}
                                            onClick={() => this.goToTask(item.taskName, item.taskId, item.startDate)}
                                            style={{cursor: "pointer"}}
                                        >
                                            <List.Item.Meta
                                            title={item.taskName}
                                            />
                                    </List.Item>
                                        </>
                                    )}
                                    />
                                </Spin>
                          </div> 
                        </Col>
                      </Row>
                    </Card>

                    <ul className="link-list-box" style={{ display: "block" }}>
                      
                      <li>
                        <div
                          onClick={() => this.goToEASiScoring()}
                          class="link-box"
                        >
                          <ServeyIcon />
                          <h4>Surveys &amp; Scores</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToDiary("diary")}
                          className={
                            selectedCol === "diary"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <DairyIcon />
                          <h4>Diary</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToPhotoGallery("gallery")}
                          className={
                            selectedCol === "gallery"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <PhotogalleryIcon />
                          <h4>Photo Gallery </h4>
                        </div>
                      </li>
                      <li>
                        <div
                          className={
                            selectedCol === "adverseEvents"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <AdverseEventIcon />
                          <h4>Adverse Events</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToActivityData("activity")}
                          class="link-box"
                        >
                          <DataIcon />
                          <h4>Activity Data</h4>
                        </div>
                      </li>
                        <li>
                        <div
                          onClick={() => this.goToFlares()}
                          class="link-box"
                        >
                          <AuditOutlined style={{fontSize: "35px"}}/>
                          <h4>Flares</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToNewMed()}
                          class="link-box"
                        >
                          <MedicationIcon/>
                          <h4>New Medication</h4>
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    medicationsList: state.medication.medicationsList,
    doctorComments: state.medication.doctorNotesList,
    medicationDetail: state.medication.medicationDetail,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...StudyActions, ...MedicationActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Flares
  )
);
