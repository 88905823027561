import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactApexChart from "react-apexcharts";
import moment from "moment-timezone";
import * as ActivityActions from "../../redux/actions/patient-activity-actions";

const dateFormat = "Do MMM";

class MoveHours extends Component {
  constructor(props) {
    super(props);
    this.state = {moveData:[]}
  }

 async componentDidMount() {
    let patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId");
    if (studyId === "4") {
      await this.props.getPhysicalActivitiesNih(patientId, studyId);
    } else {
      await this.props.getPhysicalActivities(patientId);
    }
    if(this.props.physicalActivity && this.props.physicalActivity.length>0){
      this.setState({moveData: this.props.physicalActivity})
    }



    // await this.props.getPhysicalActivities(patientId);
    // if(this.props.physicalActivity && this.props.physicalActivity.length>0){
    //   this.setState({moveData:this.props.physicalActivity})
    // }
  };

  render() {
  const {moveData} = this.state;
  
    let series = [];
    let moveHours = [];
    let seriesData = {}
    if (moveData) {
        moveData.map((moves, index) => {
        var obj = {};
        obj["x"] = moment(moves.date).format(dateFormat);
       // obj["y"] = (moves.move);
        obj["y"] =(Math.round(( (moves.move) * 1000)/10)/100).toFixed(2);
       // obj["y"] = Math.round(moves.move).toFixed(2);
        moveHours.push(obj);
      })
      series.push({ "name":"Move","data": moveHours });
      seriesData = series;
    }
    const options = {
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: true
        }
      },
      xaxis: {
        labels: {
          rotate: -90,
          // rotateAlways: false,
          // hideOverlappingLabels: true,
          showDuplicates: false,
          // trim: true,
          // minHeight: undefined,
          // maxHeight: 120,
          style: {
              colors: [],
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
          },
        },
      },
      dataLabels: {
        enabled: false
      }
    };

    return (
      <div id = "chart"  >
      {seriesData ?  <ReactApexChart options={options} series={seriesData} type="area" height={350} width={"100%"}/>:""}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    physicalActivity: state.activity.getPhysicalActivity,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({...ActivityActions}, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MoveHours)
);
