import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Badge, Menu, Dropdown, Form, Select, Button } from "antd";
import Icon from "@ant-design/icons";
import * as StudyActions from "../../redux/actions/study-actions";
import * as TaskActions from "../../redux/actions/task-actions";
import * as AuthActions from "../../redux/actions/auth-actions";
import { bindActionCreators } from "redux";
import { NotificationSvg } from "../shared/svg/notification-icon";
import * as NotificationActions from "../../redux/actions/notifications-actions";
import { notifyUser } from "../../services/notification-service";
import { DownloadOutlined } from "@ant-design/icons"

const NotificationIcon = (props) => <Icon component={NotificationSvg} {...props} />;

const { Option } = Select;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], sendTasks : false, getStudy : [], tasks: undefined, roleName: [], study: [] };
  }

  componentDidMount = () => {
    this.props
      .listUnReadNotifications()
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data.notificationsList
          
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
      this.props
        .getStudies()
        .then((resp) => {
            this.setState({
                loading: false,
                getStudy: resp.data,
            });
        })
        .catch((ex) => {
            this.setState({ loading: false });
        });
        this.props
          .getLoggedInUser()
          .then((resp) => {
            this.setState({
              loading: false,
              firstName: resp.data.firstName,
              email: resp.data.email,
              roleName:resp.data.roles[0].name,
              avatar: resp.data.firstName.toString().substring(0, 1) + resp.data.lastName.toString().substring(0, 1),
              study: resp.data.study
            });
          
          })
          .catch((ex) => {
            this.setState({ loading: false });
            this.setState({ submitted: false });
          }).finally(()=> this.setState({ submitted: false }));
  };
  
  handleSelect=(values, selectStudy, studyID)=> {
    // let selectedStudy = this.state.getStudy && this.state.getStudy.filter((item) => 
    // item.study.title == values.select);
    let selectedStudy = selectStudy.value;
    let getStudyId = selectStudy.key;
    localStorage.setItem("studyId", getStudyId)
    localStorage.setItem("studyTitle", selectedStudy)
    if (studyID == getStudyId) {
      this.props.setDefaultStudyId(getStudyId);
    }
    let studyId = localStorage.getItem("studyId")
    if (studyId === "3") {
      this.props.history.push("/patients/1/");
    } else {
      this.props.history.push("/dashboard/");
    }
    window.location.reload();
};

  toggle = () => {
    this.props.toggleSidebar();
  };

  sendTasksToPatient = async() => {
    var patientId = this.props.match.params.id;
    var studyId = localStorage.getItem("studyId");
    notifyUser("Checking pending tasks for the patient....", "info");
    if (studyId === 1) {
      var response = await this.props.sendPendingTasks(studyId, patientId);
      if(response && response.data && response.data !== "") {
        notifyUser(response.data.responseMessage, "success");
        this.props.updateTasks('send tasks');
      } else {
        if(response.error && response.error !== "") {
          notifyUser(response.error.message, "error");
        } else {
          notifyUser("Something went wrong!", "error");
        }
      }
    } else {
      var response = await this.props.sendPendingTasksJanssen(studyId, patientId);
      this.setState({loading: true});
      if(response && response.data && response.data !== "") {
        notifyUser(response.data.responseMessage, "success");
        // this.props.updateTasks('send tasks');
        await this.props
        .getJanssenAllTasksList(studyId, patientId)
        .then((resp) => {
          this.setState({
            loading: false,
            tasks: resp.data,
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
      } else {
        if(response.error && response.error !== "") {
          notifyUser(response.error.message, "error");
        } else {
          notifyUser("Something went wrong!", "error");
        }
      }
    }
  }

  deleteDuplicateTasks = async() => {
    var patientId = this.props.match.params.id;
    var studyId = localStorage.getItem("studyId");
    if  (studyId === 1) {
      var response = await this.props.deleteDuplicateTasks(studyId, patientId);
    } else {
      var response = await this.props.deleteDuplicateTasksJanssen(studyId, patientId);
    }
    if(response && response.data && response.data !== "") {
      notifyUser(response.data.responseMessage, "success");
      this.props.updateTasks('delete tasks');
    } else {
      if(response.error && response.error !== "") {
        notifyUser(response.error.message, "error");
      } else {
        notifyUser("Something went wrong!", "error");
      }
    }
  }

  downloadAllData = async() => {
    let studyId = localStorage.getItem("studyId");
    if (studyId == 1) {
      var response = await this.props.downloadAllPatientsData(studyId);
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet_stream' }));
      a.download = "AllData.zip";

      document.body.appendChild(a)
      a.click();
      document.body.removeChild(a)
      await this.props.deletePatientsZipFile();
    } else {
      var response = await this.props.downloadAllPatientsDataJanssen(studyId);
      if (response instanceof Blob) {
        notifyUser("Please wait, Your data is getting exported, Once done will send you a notification email with the data.", "success")
      } else {
        notifyUser("Server under maintenance","error")
      }
    }
  };

  render() {
    const userRole = this.state.roleName;
    const userStudy = this.state.study
    const currentUrl = window.location.href;
    const hasNotification = currentUrl.includes('/notifications');
    const studyID = localStorage.getItem("studyId");
    const menus = this.state.data.slice(0, 5).map((item) => {
      return (
        <Menu.Item key={item.id} >
          {item.kindDescription}
        </Menu.Item>
      )
    });
    const listStudies = this.state.getStudy
    let studies = [];
    // if (listStudies && listStudies !== undefined && userRole == "ADMIN") {
    //   studies = listStudies.map((data) => 
    //   <Option
    //       key={data.study.id} value={data.study.title} >
    //       {data.study.title}
    //   </Option>)
    // }  

    if (listStudies && listStudies !== undefined && userRole == "ADMIN") {
        studies = listStudies.map((data) => <Option
            key={data.study.id} value={data.study.title} >
            {data.study.title}
        </Option>)
    } 
    else if (listStudies && listStudies !== undefined) {
        studies = userStudy.map((data) => <Option
            key={data.id} value={data.title} >
            {data.title}
        </Option>)
    }

  const selectStudy = this.state.getStudy;
  let DValue = null;
  if(studyID){
    const getStudyInfo = selectStudy && selectStudy.find(item=>item.study.id == studyID);
    // DValue = getStudyInfo && getStudyInfo.study.title && studyID == 1 ? getStudyInfo.study.title : "JANSSEN SKINTRACKER STUDY";
    DValue = getStudyInfo && getStudyInfo.study.title;
  }
  const formLayout = "horizontol";
    const menu = () => {
      return (
        <div className="notification-dropdown">
          <Menu onClick={() => this.props.history.push("/notifications/")}>
            {menus}
          </Menu>
          <p className="view-link-right"><a onClick={() => this.props.history.push("/notifications/")} className="ant-link" >View All</a></p>
        </div>
      )
    };

    return (
      <div id="header">
        <Row className="header-row">
          <Col>
            <span className="sidemenu-trigger">
              {

                /* {React.createElement(this.state.collapsed ? MenuOutlined : MenuOutlined, {
                  className: 'trigger',
                  onClick: this.toggle,
                }
              )} */}
            </span>
          </Col>
          <Col>
          
          </Col>

          <Col
            className="header-right"
          >
            {/* SEARCH FIELD */}

            {/* /SEARCH FIELD */}
            {/* NOTIFICATIONS */}
            {
              this.props.isPatientProgress && studyID == "1" ? <>
              <Button onClick={() => this.deleteDuplicateTasks()}>Delete duplicate Tasks</Button>
              <Button onClick={() => this.sendTasksToPatient()}> Send Tasks</Button></>
              : ""
            }
            {this.props.isDashboard  && userStudy && userStudy.length > 1 && userRole !== "SUPERADMIN"?
              <Form
                    layout={formLayout}
                    className="dahsboard-studies-dropdown header-drop"
                    // onFinish={this.handleSubmit}
                >
                    <Form.Item
                        name="select"
                        label="Select Study"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please select Study!',
                        //     },
                        // ]}
                    >{console.log('DValue 1',DValue)}
                        <Select placeholder="Select Study" onSelect={this.handleSelect} value={DValue} key={DValue}>
                            {studies}
                        </Select>
                    </Form.Item>
                    {/* <Form.Item className="form-button-group">
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Form.Item> */}
                </Form>
                : this.props.isDashboard && userRole == "ADMIN" ?
                <Form
                    layout={formLayout}
                    className="dahsboard-studies-dropdown"
                    // onFinish={this.handleSubmit}
                >
                    <Form.Item
                        name="select"
                        label="Select Study"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please select Study!',
                        //     },
                        // ]}
                    >{console.log('DValue',DValue)}
                        <Select placeholder="Select Study" onSelect={this.handleSelect} value={DValue} key={DValue}>
                            {studies}
                        </Select>
                    </Form.Item>
                    {/* <Form.Item className="form-button-group">
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Form.Item> */}
                </Form>
                : null
            }
            {this.props.isDashboard ? 
            <Button onClick={() => this.downloadAllData()}><DownloadOutlined />All Patients data</Button>
            :""
          } { userRole !== "SUPERADMIN" && studyID === "1" ?
          <Dropdown
          overlay={menu}
          className="notifications"
        >

          <Badge
            style={{ background: "#2c6ba7" }} count={this.state.data && this.state.data.length > 0 ? this.state.data.length : ""}

          >
            <a onClick={() => this.props.history.push("/notifications/")}><NotificationIcon /></a>
          </Badge>

        </Dropdown> : null }
            
            {/* /NOTIFICATIONS */}
          </Col>
        </Row>
      </div>
    );
  }
}
//function mapStateToProps(state) {
//return { defaultStudyId:state.study.defaultStudy};
//}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...StudyActions, ...NotificationActions, ...AuthActions, ...TaskActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(Header)
);
