import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";

import * as MedicationActions from "../../redux/actions/medication-actions";
import "react-h5-audio-player/lib/styles.css";

import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Button,
  Form,
  Input,
  Comment,
  Tooltip,
  PageHeader,
} from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import { LabSvg } from "../shared/svg/labicon";
import { AuditOutlined } from "@ant-design/icons";
import { MedicationIconSvg } from "../shared/svg/medication";


const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const MedicationIcon = (props) => (
  <Icon component={MedicationIconSvg} {...props} />
);

const dateFormat = "Do MMM";
class Medication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      formKey: 0,
      submitted: false,
      patientVoiceDiary: [],
      selectItemId: "",
      selectedCol: "",
      medication: undefined,
      medicationId: "",
      detailLoading: false,
      medicationList: undefined,
      medicationDetailData: undefined 
    };
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    let patientId = this.props.match.params.id;
    // await this.props.getPatientById(patientId);
    if (studyId == 2) {
      await this.props.getPatientByIdJanssen(patientId);
    } else if (studyId == 4){
      await this.props.getPatientByIdNih(patientId);
    } else {
      await this.props.getPatientById(patientId);
    }
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      this.setState({ loading: false });
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
    let data = await this.props.listMedications(patientId, studyId);
    if (this.props.medicationsList) {
      this.setState({ medicationList: data.data, loading: false });
    }
    // await this.props.getVoiceDiaries(patientId);
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  addDoctorNotesOnMedication = async (values) => {
    this.setState({ formKey: (this.state.formKey || 0) + 1 });

    let self = this;
    if (self.state.medicationId === "" || self.state.medicationId === null) {
      notifyUser("Please select any medication", "error");
    } else {
      let data = {
        doctorNote: values.doctorNotes,
        patientId: this.props.match.params.id,
        medicationId: this.state.selectItemId,
      };

      this.setState({ submitted: true });
      await self.props.addDoctorNotesOnMedication(data).then((response) => {
        if (response.error && response.error !== "") {
          notifyUser(response.error.message, "error");
          self.setState({ submitted: false });
        } else {
          if (response.data && response.data !== "") {
            this.props.getDoctorCommentsOnMedication(this.state.selectItemId);
            notifyUser("Doctor note added Successfully", "success");
            self.setState({ submitted: false });
          }
        }
      });
    }
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };
  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-progress/" + patientId);
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToLab = (id) => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/lab/" + patientId);
  };

  async goToMedication(item) {
    var patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId");
    let data = {
      patientId: patientId,
      medicationId: item.medicationId,
      studyId: studyId,
      adverseEventId: item.adverseEventId ? item.adverseEventId : null,
    };
    if (studyId == 4){
      this.setState({ detailLoading: true });
      let medData = await this.props.medicationDetailsNih(data);
      if (this.props.medicationDetail) {
        this.setState({
          selectItemId: item.medicationId,
          medication: this.props.medicationDetail,
          medicationId: item.medicationId,
          detailLoading: false,
          medicationDetailData: medData.data
        });
      }
    } else {
      this.setState({ detailLoading: true });
      let medData = await this.props.medicationDetails(data);
      if (this.props.medicationDetail) {
        this.setState({
          selectItemId: item.medicationId,
          medication: this.props.medicationDetail,
          medicationId: item.medicationId,
          detailLoading: false,
          medicationDetailData: medData.data
        });
      }

    }
    // this.setState({ selectItemId: item.medicationId,medication:item,medicationId:item.medicationId})
    await this.props.getDoctorCommentsOnMedication(item.medicationId);
  }

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  sortMedications = (medications) => {
    return medications && medications.sort((a, b) => {
      const nameA = a.medicineName || a.SupplementName || "";
      const nameB = b.medicineName || b.SupplementName || "";
      return nameA.localeCompare(nameB);
    });
  };

  goToNewMed = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/new-medication/" + patientId);
  }

  render() {

    let studyId = localStorage.getItem("studyId");
    const { submitted, selectItemId, selectedCol, medication, detailLoading, medicationList, medicationDetailData } =
      this.state;

    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    const sortedMedicationList = this.sortMedications(medicationList);
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }

    const patientDataHealth = this.props.patientData && this.props.patientData[0];

    const isTimestamp = (value) => !isNaN(value) && parseInt(value) > 0;
    // Function to format timestamp to dd/mm/yyyy
    const formatDate = (timestamp) => {
      const date = new Date(parseInt(timestamp));
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      
      // Pad single-digit day/month with leading zero
      const formattedDay = day < 10 ? '0' + day : day;
      const formattedMonth = month < 10 ? '0' + month : month;

      return `${formattedMonth}/${formattedDay}/${year}`;
    };
    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <PageHeader
              className="site-header-title"
              title={
                this.state.data && this.state.data.title
              }
            ></PageHeader>
            <Spin spinning={submitted}>
              {!this.state.loading && (
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={6} xl={6}>
                    <div className="white-box user-section">
                      <div className="user-profile">
                        <Link to={"/patient-progress/" + this.props.match.params.id} >
                          <Avatar size={84}>
                            {this.props.patientData &&
                              this.props.patientData[0].firstName
                                .toUpperCase()
                                .charAt(0) +
                              this.props.patientData[0].lastName
                                .toUpperCase()
                                .charAt(0)}
                          </Avatar>
                        </Link>
                        <div className="profile-details">
                          <Link to={"/patient-progress/" + this.props.match.params.id} >
                            <h3>
                              {this.props.patientData &&
                                this.props.patientData[0].firstName +
                                " " +
                                this.props.patientData[0].lastName}
                            </h3>
                          </Link>
                          {studyId === 1 ? 
                          <button
                          className="btn"
                          onClick={() => this.goToChat()}
                        >
                          Chat Now
                        </button> : ""
                          }
                          
                        </div>
                      </div>
                      <div className="user-details">
                        <List
                          style={{ marginTop: "40px" }}
                          itemLayout="horizontal"
                          dataSource={this.props.patientData}
                          renderItem={(patient) => (
                            <>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Study:
                                </Typography.Text>{" "}
                                {patient.title}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Email:
                                </Typography.Text>{" "}
                                {patient.email}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Date of Birth:
                                </Typography.Text>{" "}
                                {/* {patient.dateOfBirth
                                  ? moment(new Date(Number(patient.dateOfBirth))).format(
                                    "DD-MM-YYYY"
                                  )
                                  : "N/A"} */}
                                  {finalDate}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Age:
                                </Typography.Text>{" "}
                                {patient.age ? patient.age + " years" : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Phone Number:
                                </Typography.Text>{" "}
                                {"(" +
                                  patient.phoneCode +
                                  ") " +
                                  patient.phoneNumber}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Member Status:
                                </Typography.Text>{" "}
                                {patient.userScreeningStatus.replace("_", " ")}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Registered Date:
                                </Typography.Text>{" "}
                                {patient.createdDate
                                  ? moment(patient.createdDate)
                                    .format(dateFormathh)
                                  : "N/A"}
                              </List.Item>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={18} xl={18}>
                    <Card
                      title={"Medication"}
                      extra={
                        <Button onClick={() => this.goToPatientProgress()}>
                          Back
                        </Button>
                      }
                      style={{ marginBottom: "8px", padding: "0 16px" }}
                      className="patient-dairy-section"
                    >
                      <Row gutter={0}>
                        <Col xs={24} sm={12} md={8} xl={8}>
                          {/* Activity Tabs START */}
                          <div className="card-container">
                            <List
                              itemLayout="horizontal"
                              dataSource={sortedMedicationList}
                              renderItem={(item) => (
                                <List.Item
                                  onClick={() => this.goToMedication(item)}
                                  className={
                                    selectItemId === item.medicationId
                                      ? "selected-item-list"
                                      : "item-list"
                                  }
                                >
                                  <List.Item.Meta
                                    avatar={
                                      <span className="date">
                                        {item.startDate
                                          ? moment(item.startDate).format(
                                            dateFormat
                                          )
                                          : "date"}
                                      </span>
                                    }
                                    title={
                                      item.medicineName
                                        ? item.medicineName :
                                        item.SupplementName ?
                                        item.SupplementName
                                        : "medication"
                                    }
                                  />
                                </List.Item>
                              )}
                            />
                          </div>
                          {/* /Activity Tabs END */}
                        </Col>
                        <Col xs={24} sm={12} md={16} xl={16}>
                          {/* Activity Content Section Start */}
                          <div className="dairy-content-section">
                            <div className="play-icon-container"></div>
                            <List
                              itemLayout="horizontal"
                              className="screening-ques"
                              dataSource={medicationDetailData}
                              renderItem={(quesAnsData, index) => (
                                <>
                                  <List.Item>
                                    <List.Item.Meta
                                      title={
                                        <span>
                                          <b>{"Q" + (index + 1) + ". "}</b>{" "}
                                          {quesAnsData.question}
                                        </span>
                                      }
                                      description={
                                        <span>
                                          <b>{"A" + (index + 1) + ". "}</b>{" "}
                                          {/* {quesAnsData.answer} */}
                                          {isTimestamp(quesAnsData.answer)
                                          ? formatDate(quesAnsData.answer)
                                          : quesAnsData.answer}
                                        </span>
                                      }
                                    />
                                  </List.Item>
                                </>
                              )}
                            />
                            <Spin spinning={detailLoading}>
                              {medication ? (
                                <Form
                                  layout="vertical"
                                  key={this.state.formKey}
                                  onFinish={this.addDoctorNotesOnMedication}
                                >
                                  {this.props.doctorComments ? (
                                    <div className="previous-comment-section">
                                      <Form.Item label="Previous Comments">
                                        <div
                                          style={{
                                            maxHeight: "300px",
                                            overflow: "auto",
                                          }}
                                        >
                                          {this.props.doctorComments.map(
                                            (comment) => {
                                              return (
                                                <div>
                                                  <Comment
                                                    key={comment.id}
                                                    author={comment.doctorName}
                                                    content={comment.doctorNote}
                                                    datetime={
                                                      <Tooltip>
                                                        {moment(
                                                          comment.noteDate
                                                        ).format(dateFormathh)}
                                                      </Tooltip>
                                                    }
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </Form.Item>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <Form.Item
                                    name="doctorNotes"
                                    label="Doctor Notes"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please add comment!",
                                        whitespace: true,
                                      },
                                    ]}
                                  >
                                    <Input.TextArea placeholder="Add comment..." />
                                  </Form.Item>

                                  <Form.Item className="btn-form-container">
                                    <Button type="primary" htmlType="submit">
                                      Submit
                                    </Button>
                                  </Form.Item>
                                </Form>
                              ) : (
                                ""
                              )}
                            </Spin>
                          </div>

                          {/* /Activity Content Section End */}
                        </Col>
                      </Row>
                    </Card>

                    <ul className="link-list-box" style={{ display: "block" }}>
                      {
                        studyId === 1 ?
                        <li>
                        <div onClick={() => this.goToChat()} class="link-box">
                          <ChatIcon />
                          <h4>Chat</h4>
                        </div>
                      </li> : ""
                      }
                      
                      <li>
                        <div
                          onClick={() => this.goToEASiScoring()}
                          class="link-box"
                        >
                          <ServeyIcon />
                          <h4>Surveys &amp; Scores</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToDiary("diary")}
                          className={
                            selectedCol === "diary"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <DairyIcon />
                          <h4>Diary</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToPhotoGallery("gallery")}
                          className={
                            selectedCol === "gallery"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <PhotogalleryIcon />
                          <h4>Photo Gallery </h4>
                        </div>
                      </li>
                      <li>
                        <div
                          className={
                            selectedCol === "adverseEvents"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <AdverseEventIcon />
                          <h4>Adverse Events</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToActivityData("activity")}
                          class="link-box"
                        >
                          <DataIcon />
                          <h4>Activity Data</h4>
                        </div>
                      </li>
                      {
                        studyId === 1 ?
                        <li>
                        <div
                          onClick={() => this.goToLab("lab")}
                          class="link-box"
                        >
                          <LabIcon />
                          <h4>Lab</h4>
                        </div>
                      </li> :""
                      }
                      {
                        studyId == 4 && (patientDataHealth && patientDataHealth.healthCondition !== "HEALTHY_CONTROL") ?
                        <li>
                          <div
                            onClick={() => this.goToFlares()}
                            class="link-box"
                          >
                            <AuditOutlined style={{fontSize: "35px"}}/>
                            <h4>Flares</h4>
                          </div>
                        </li>
                        : ""
                      }
                      {
                        studyId === "4" ?
                        <li>
                          <div
                            onClick={() => this.goToNewMed()}
                            class="link-box"
                          >
                            <MedicationIcon/>
                            <h4>New Medication</h4>
                          </div>
                        </li>
                        : ""
                      }
                    </ul>
                  </Col>
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    medicationsList: state.medication.medicationsList,
    doctorComments: state.medication.doctorNotesList,
    medicationDetail: state.medication.medicationDetail,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...StudyActions, ...MedicationActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Medication
  )
);
