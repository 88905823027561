import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";
import * as PatientDiaryActions from "../../redux/actions/patient-diary-actions";
import "react-h5-audio-player/lib/styles.css";
import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Tabs,
  Modal,
  Carousel,
  Button,
  Form,
  Input,
  PageHeader,
  Upload,
  Tooltip,
  DatePicker
} from "antd";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  UploadOutlined,
  DownloadOutlined,
  AuditOutlined
} from "@ant-design/icons";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import { StudyDrugLogSvg } from "../shared/svg/studydruglog.jsx";
import { LabSvg } from "../shared/svg/labicon";
import { MedicationIconSvg } from "../shared/svg/medication";

const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const StudyDrugLogIcon = (props) => (
  <Icon component={StudyDrugLogSvg} {...props} />
);
const MedicationIcon = (props) => (
  <Icon component={MedicationIconSvg} {...props} />
);

const { TabPane } = Tabs;
const ButtonGroup = Button.Group;

class PhotoGallery extends Component {
  formRef = React.createRef();
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      image: {},
      isModalVisible: false,
      submitted: false,
      selectedCol: "gallery",
      bodypartType: "HEAD",
      bodypartTypeByWeek: "FULL_BODY",
      partTypeNih: "FULL_BODY",
      partType: "Head Image",
      partTypeByWeek: "Full Body Image",
      month: 1,
      week: 1,
      monthnih: 1,
      photoGallery: [],
      photoGalleryByWeek: undefined,
      easiScores: undefined,
      selectedMonth: 1,
      selectedWeek: 1,
      selectedMonthNih: 1,
      section: "section1",
      sectionWeek: "section1",
      sectionPart: "Section 1 images",
      imageName: "rightElbowCrease",
      quatar: 1,
      paradeUser: undefined,
      currentImageIndex: 0,
      visibleModal: false,
      fileList: [],
      imageBase64: '',
      selectedFile: null,
      selectedPhotoDate: undefined,
      isDisable: false,
      selection: null,
      shape: 'box', // Can be 'box' or 'circle'
      shapes: [],
      imgWidth: 400, // Set the desired canvas width
      imgHeight: 300,
      selectedImage: null,
      isImageLoading: false,
      rotationAngles: (props.photoGalleryByWeek && props.photoGalleryByWeek.length) ? props.photoGalleryByWeek.map(() => 0) : [],
    };
    this.canvasRef = React.createRef();
  }

  async componentDidMount() {
    this._isMounted = true;
    try {
      let studyId = localStorage.getItem("studyId");
      if (studyId == "3") {
        this.setState({ sectionPart: "Right elbow crease"});
      }
      let section = this.state.section
      let imageName = this.state.imageName;
      let quatar = this.state.quatar;
      this.props
        .getStudyById(studyId)
        .then((resp) => {
          this.setState({
            //  loading: false,
            data: resp.data,
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
      let patientId = this.props.match.params.id;
      // await this.props.getPatientById(patientId);
      if (studyId == "2") {
        await this.props.getPatientByIdJanssen(patientId);
      } else if (studyId == "1") {
        await this.props.getPatientById(patientId);
      } else if (studyId == "4") {
        await this.props.getPatientByIdNih(patientId);
      } else {
        await this.props.getPatientByIdParade(patientId);
        this.setState({paradeUser: this.props.patientData});
      }
      if (
        this.props.patientData &&
        this.props.patientData.length > 0 &&
        this.props.patientData[0]
      ) {
        //this.setState({ loading: false })
      }
      let month = this.state.month;
      let week = this.state.week;
      
      let partType = this.state.bodypartType;
      let partTypeNih = this.state.partTypeNih;
      if (studyId == 1) {
        let data = await this.props.getPhotoGalleryByWeek(studyId, month, patientId, partType);
        if (this.props.easiScores) {
          this.setState({ photoGallery: this.props.photoGallery });
          this.setState({ easiScores: this.props.easiScores });
        } else if (this.props.patientDataError) {
          notifyUser(this.props.patientDataError, "error");
          this.props.history.push("/patients/1");
        }
      } else  if (studyId == 2) {
        let dataByWeek = await this.props.getJansennPhotoGalleryByWeekNew(section, patientId, studyId, week, month);
        if (this.props.easiScores) {
          this.setState({ photoGallery: this.props.photoGalleryByWeek });
          this.setState({ easiScores: this.props.easiScores });
        } else if (this.props.patientDataError) {
          notifyUser(this.props.patientDataError, "error");
          this.props.history.push("/patients/1");
        }
      } else if (studyId == 3) {
        let dataByQuater = await this.props.getAllOhsuImagesForPhysicianPortal(imageName, patientId, studyId, quatar);
        if (dataByQuater.data.allImages && dataByQuater.data.allImages.length > 0) {
          this.setState({ photoGallery: this.props.photoGalleryByWeek });
        } else if (this.props.patientDataError) {
          notifyUser(this.props.patientDataError, "error");
          this.props.history.push("/patients/1");
        }
      } else {
          let monthnih = this.state.monthnih;
          this.setState({sectionPart: "Full Body images"});
          let nihData = await this.props.getNihPhotoGallery(monthnih, partTypeNih, patientId, studyId, week);
          if (this.props.nihPhotoGallery) {
            this.setState({ photoGallery: this.props.photoGalleryByWeek });
            this.setState({ easiScores: this.props.easiScores });
          } else if (this.props.patientDataError) {
            notifyUser(this.props.patientDataError, "error");
            this.props.history.push("/patients/1");
          }
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    this.setState({ loading: false });
       
  }
  componentWillUnmount() {
    // Set a flag to indicate that the component is unmounted
    this._isMounted = false;
  }

  async componentDidUpdate(prevProps, prevState) {
    // Check if the relevant state or props have changed
    if (this.state.photoGallery !== prevState.photoGallery) {
      // Perform any actions or side-effects here
    }
    if (prevProps.photoGalleryByWeek !== this.props.photoGalleryByWeek) {
      this.setState({
        rotationAngles: this.props.photoGalleryByWeek && this.props.photoGalleryByWeek.length ? this.props.photoGalleryByWeek.map(() => 0) : [],
      });
    }
    if (
      this.state.isModalVisible !== prevState.isModalVisible ||
      this.state.currentImageIndex !== prevState.currentImageIndex
    ) {
      this.redrawCanvas();
    }
  }

  async componentWillReceiveProps(nextProps) {
    let studyId = localStorage.getItem("studyId")
    if (studyId == 1) {
      if (this.props.easiScores !== nextProps.easiScores) {
        this.setState({ photoGallery: nextProps.photoGallery });
        this.setState({ easiScores: nextProps.easiScores });
        this.setState({ loading: false });
      }
    } else {
      if (this.props.easiScores !== nextProps.easiScores) {
        this.setState({ photoGallery: nextProps.photoGalleryByWeek });
        this.setState({ easiScores: nextProps.easiScores });
        this.setState({ loading: false });
      }
    }
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };

  goToDiary = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId");
    if (studyId === "3") {
      this.props.history.push("/patients/1/");
    } else {
      this.props.history.push("/patient-progress/" + patientId);
    }
  };

  goToLab = (id) => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/lab/" + patientId);
  };

  async getPhotos(month) {
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let partType = this.state.bodypartType;
    this.setState({ month: month, selectedMonth: month, loading: true });

    await this.props.getPhotoGalleryByWeek(studyId, month, patientId, partType);

    if (this.props.easiScores) {
      this.setState({ photoGallery: this.props.photoGallery });
      this.setState({ easiScores: this.props.easiScores, loading: false });
      // console.log(this.props.photoGalleryByWeek)
    }
  }

  async getPhotosByWeek(week) {
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let section = this.state.section;
    let month = this.state.month;
    await this.setState({ week: week, selectedWeek: week, loading: true });

    await this.props.getJansennPhotoGalleryByWeekNew(section, patientId, studyId, month, week);

    if (this.props.easiScores) {
      this.setState({ photoGallery: this.props.photoGalleryByWeek });
      this.setState({ easiScores: this.props.easiScores, loading: false });
      // console.log(this.props.photoGalleryByWeek)
    }
  }

  async getPhotosParade(quatarOhsu) {
    await this.setState({ quatar: quatarOhsu, selectedWeek: quatarOhsu, loading: true });
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let imageName = this.state.imageName;
    let quatar = this.state.quatar;

    let photos = await this.props.getAllOhsuImagesForPhysicianPortal(imageName, patientId, studyId, quatarOhsu);
    if (photos) {
      this.setState({ photoGallery: this.props.photoGalleryByWeek, loading: false });
      // console.log(this.props.photoGalleryByWeek)
    }
  }

  async getPhotosByMonth(monthnih) {
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let partTypeNih = this.state.partTypeNih;
    this.setState({ monthnih: monthnih, selectedMonthNih: monthnih, loading: true });
    let month = this.state.monthnih;
    let week = this.state.week;

    await this.props.getNihPhotoGallery(monthnih, partTypeNih, patientId, studyId, week);

    if (this.props.easiScores) {
      this.setState({ photoGallery: this.props.photoGalleryByWeek });
      this.setState({ easiScores: this.props.easiScores, loading: false });
      // console.log(this.props.photoGalleryByWeek)
    }
  }

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  showModal = (img, index) => {
    this.setState(
      { isModalVisible: true,
        currentImageIndex: index,
        image: img },
      () => {
        // Callback to draw the image after state is set
        if (this.canvasRef.current) {
          this.drawImageOnCanvas();
        }
      }
    );
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false, image: {}, visibleModal: false, fileList: [], imageBase64: '', selectedFile: null});
    this.resetImage();
    if (this.formRef.current) {
      this.formRef.current.resetFields(); // Ensure formRef is not null before calling resetFields
    }
    
  };

  goToStudyDrugLog = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/study-drug-log/" + patientId);
  };

  downloadImage = async () => {
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let partType = this.state.bodypartType;
    var response = await this.props.downloadImages(studyId, this.state.month, patientId, partType);
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet_stream' }));
    a.download = "images.zip";

    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
    await this.props.deleteZipFile();
  };

  downloadImageWeek = async () => {
    notifyUser("Download images in progress, it might take a little while so be patient..", "loading");
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let section = this.state.section;
    let week = this.state.week;
    let month = this.state.month
    var response = await this.props.downloadImagesJanssenNew(section, patientId, studyId, month, week);
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet_stream' }));
    a.download = "images.zip";

    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
    let deleteZip = await this.props.deleteZipFile();
    notifyUser("Images downloaded successfully", "success");      
  };

  downloadImageOhsu = async () => {
    notifyUser("Download images in progress, it might take a little while so be patient..", "loading");
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let imageName = this.state.imageName;
    let quatar = this.state.quatar;
    var response = await this.props.downloadImagesOhsu("allImages", studyId, quatar, patientId);
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet_stream' }));
    a.download = "images.zip";

    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
    // let deleteZip = await this.props.deleteZipFile();
    notifyUser("Images downloaded successfully", "success");      
  };

  downloadImageNih = async () => {
    notifyUser("Download images in progress, it might take a little while so be patient..", "loading");
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let partTypeNih = this.state.partTypeNih;
    let week = this.state.week;
    let month = this.state.monthnih
    var response = await this.props.downloadImagesNih(month, "FULL_BODY", patientId, studyId, week);
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet_stream' }));
    a.download = "images.zip";

    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
    // let deleteZip = await this.props.deleteZipFile();
    notifyUser("Images downloaded successfully", "success");      
  };

  handleSelect = async (key) => {
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let partType = key;
    let month = this.state.month;
    this.setState({ loading: true });

    await this.props.getPhotoGalleryByWeek(studyId, month, patientId, partType);

    if (this.props.easiScores) {
      this.setState({ photoGallery: this.props.photoGallery });
      this.setState({ easiScores: this.props.easiScores });
      this.setState({ loading: false });
    }

    this.setState({ bodypartType: key });
    if (key === "HEAD") {
      this.setState({ partType: "Head Images" });
    }
    if (key === "UPPER_EXTREMITY") {
      this.setState({ partType: "Upper extremity Images" });
    }
    if (key === "LOWER_EXTREMITY") {
      this.setState({ partType: "Lower extremity Images" });
    }
    if (key === "TRUNK") {
      this.setState({ partType: "Trunk Images" });
    }
    if (key === "ALL") {
      this.setState({ partType: "ALL" });
    }
    //  this.setState({bodypartType:key})
  };

  handleSelectWeek = async (key) => {
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let section = key;
    let month = this.state.month
    let week = this.state.week;
    this.setState({ loading: true });

    this.setState({ section: key });
    if (key === "section1") {
      let photoData = await this.props.getJansennPhotoGalleryByWeekNew(section, patientId, studyId, month, week);

      if (this.props.easiScores) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
      this.setState({ sectionPart: "Section 1 Images" });
    }
    if (key === "section2") {
      let photoData = await this.props.getJansennPhotoGalleryByWeekNew(section, patientId, studyId, month, week);

      if (this.props.easiScores) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
      this.setState({ sectionPart: "Section 2 Images" });
    }
    if (key === "section3") {
      let photoData = await this.props.getJansennPhotoGalleryByWeekNew(section, patientId, studyId, month, week);

      if (this.props.easiScores) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
      this.setState({ sectionPart: "Section 3 Images" });
    }
    if (key === "section4") {
      let photoData = await this.props.getJansennPhotoGalleryByWeekNew(section, patientId, studyId, month, week);

      if (this.props.easiScores) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
      this.setState({ sectionPart: "Section 4 Images" });
    }
    if (key === "section5") {
      let photoData = await this.props.getJansennPhotoGalleryByWeekNew(section, patientId, studyId, month, week);

      if (this.props.easiScores) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
      this.setState({ sectionPart: "Section 5 Images" });
    }
    if (key === "allImages") {
      let photoData = await this.props.getJansennPhotoGalleryByWeekNew(section, patientId, studyId, month, week);

      if (this.props.easiScores) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
      this.setState({ sectionPart: "All Images" });
    }
    //  this.setState({bodypartType:key})
  };

  handleSelectParade = async (key) => {
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let imageName = key;
    let quatar = this.state.quatar;
    this.setState({ loading: true });

    this.setState({ imageName: key });
    await this.props.getAllOhsuImagesForPhysicianPortal(key, patientId, studyId, quatar);
    if (this.props.photoGalleryByWeek) {
      await this.setState({ photoGallery: this.props.photoGalleryByWeek });
      this.setState({ loading: false });
    }
    if (key === "rightElbowCrease") {
      await this.setState({ sectionPart: "Right elbow crease" });
    }
    if (key === "leftElbowCrease") {
      await this.setState({ sectionPart: "Left elbow crease" });
    }
    if (key === "creaseBehindRightKnee") {
      await this.setState({ sectionPart: "Crease behind right knee" });
    }
    if (key === "creaseBehindLeftKnee") {
      await this.setState({ sectionPart: "Crease behind left knee" });
    }
    if (key === "frontOfNeck") {
      await this.setState({ sectionPart: "Front of neck" });
    }
    if (key === "optionalImages") {
      await this.setState({ sectionPart: "Additional Area for Rash" });
    }
    if (key === "allImages") {
      await this.setState({ sectionPart: "All Images" });
    }
     this.setState({loading: false })
  };

  handleSelectNih = async (key) => {

    let month = this.state.monthnih;
    let week = this.state.week;
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    this.setState({ loading: true });
    this.setState({ sectionPart: "Full Body images" });

    this.setState({ partTypeNih: key });
    if (key === "FULL_BODY") {
      let photoData = await this.props.getNihPhotoGallery(month, key, patientId, studyId, week);

      if (this.props.photoGalleryByWeek) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
    }
    if (key === "UPPER_BODY") {
      let photoData = await this.props.getNihPhotoGallery(month, key, patientId, studyId, week);
      this.setState({ sectionPart: "Upper Body images" });


      if (this.props.photoGalleryByWeek) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
    }
    if (key === "LOWER_BODY") {
      let photoData = await this.props.getNihPhotoGallery(month, key, patientId, studyId, week);

      if (this.props.easiScores) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
      this.setState({ sectionPart: "Lower Body images" });
    }
    if (key === "HEAD_AND_NECK") {
      let photoData = await this.props.getNihPhotoGallery(month, key, patientId, studyId, week);

      if (this.props.easiScores) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
      this.setState({ sectionPart: "Head and Neck images" });
    }
    if (key === "STANDARDISED_CLOSE_UP") {
      let photoData = await this.props.getNihPhotoGallery(month, key, patientId, studyId, week);

      if (this.props.easiScores) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
      this.setState({ sectionPart: "Where disease is the worst images" });
    }
    if (key === "ALL") {
      let photoData = await this.props.getNihPhotoGallery(month, key, patientId, studyId, week);

      if (this.props.easiScores) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek });
        this.setState({ easiScores: this.props.easiScores });
        this.setState({ loading: false });
      }
      this.setState({ sectionPart: "All images" });
    }
    //  this.setState({bodypartType:key})
  };

  // goToPatientProgress = () => {
  //   var patientId = this.props.match.params.id;
  //   this.props.history.push("/patient-progress/" + patientId);
  // };

 rotateImage = (index, direction) => {
  console.log("tttttttttt",index, direction);
  
    this.setState((prevState) => {
      const newAngles = [...prevState.rotationAngles];
      newAngles[index] += direction === 'left' ? -90 : 90;
      return { rotationAngles: newAngles };
    });
  };

  photoUpload = () => {
    this.setState({visibleModal: true});
  }


  handleFileRemove = async (file) => {
    await this.setState({selectedFile: null, fileList: [], imageBase64: undefined})
    this.setState((state) => {
      const newFileList = state.fileList.filter((item) => item.uid !== file.uid);
      return {
        fileList: newFileList,
        selectedFile: null,
      };
    });
  };

  // handleFileRemove = (file) => {
  //   this.setState((state) => {
  //     const index = state.fileList.indexOf(file);
  //     const newFileList = state.fileList.slice();
  //     newFileList.splice(index, 1);
  //     return {
  //       fileList: newFileList,
  //     };
  //   });
  // };

  handleFileChange = ({ file }) => {
    if (file.status === 'removed') {
      // Handle the file removal logic here if needed
      return;
    }
    if (file) {
      const isJpgOrJpeg = file.type === 'image/jpeg' || file.type === 'image/jpg';
      if (!isJpgOrJpeg) {
        notifyUser("You can only upload JPG/JPEG files!", "error")
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result.split(',')[1];
        this.setState({ imageBase64: base64String });
      };
      reader.readAsDataURL(file);
      this.setState({ selectedFile: file, fileList: [file] });
    }
  };

  handleAdd = async () => {
    this.setState({isDisable: true});
    let patientId = this.props.match.params.id;
    let month = this.state.monthnih;
    try {
      const values = await this.formRef.current.validateFields();
      const { imageBase64, selectedPhotoDate } = this.state;
      if (!imageBase64) {
        notifyUser("Please upload an image", "error");
        this.setState({isDisable: false});
        return;
      }

      // Concatenate selectedDate with title
      const updatedTitle = `${values.title}-${selectedPhotoDate}`;

      // Combine form values with the base64 image string and updated title
      const formData = { ...values, title: updatedTitle, imageBase64 };

      // Combine form values with the base64 image string
      // const formData = { ...values, imageBase64 };

      let uploadImage = await this.props.uploadPartPhoto(patientId, formData.title, month, formData.imageBase64);
      if (uploadImage && uploadImage.data.isSuccess) {
        notifyUser(uploadImage.data.responseMessage+" and will be displayed in all images", "success");
        this.formRef.current.resetFields();
        this.setState({isDisable: false});
        this.handleCancel();
        this.componentDidMount();
      } else {
        notifyUser('Image upload failed', "error");
        this.setState({isDisable: false});
      }
    } catch (errorInfo) {
      console.log('Validation Failed:', errorInfo);
      this.setState({isDisable: false});
    }
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  onDateChange = (date, dateString) => {
    if (date !== null) {
      const formattedDate = moment(date._d).format('MM-DD-YYYY');
      this.setState({ selectedPhotoDate: formattedDate });   
    }
  };

  disabledFutureDates = (current) => {
    // Disable dates after today
    return current && current > Date.now();
  };

  gotToNewRash = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/new-rash-photogallery/" + patientId);
  }

  handleMouseDown = (e) => {
    const rect = this.canvasRef.current.getBoundingClientRect();
    this.setState({
      selection: {
        startX: e.clientX - rect.left,
        startY: e.clientY - rect.top,
        endX: null,
        endY: null,
      },
    });
  };

  handleMouseMove = (e) => {
    const { selection } = this.state;
    if (selection) {
      const rect = this.canvasRef.current.getBoundingClientRect();
      this.setState({
        selection: {
          ...selection,
          endX: e.clientX - rect.left,
          endY: e.clientY - rect.top,
        },
      });
      this.redrawCanvas();
    }
  };

  handleMouseUp = () => {
    const { selection, shape, shapes } = this.state;
    if (selection) {
      const newShape = { ...selection, shapeType: shape };
      this.setState(
        {
          shapes: [...shapes, newShape],
          selection: null,
        },
        () => this.redrawCanvas()
      );
    }
  };

  // redrawCanvas = () => {
  //   const canvas = this.canvasRef.current;
  //   const ctx = canvas.getContext('2d');
  //   const img = new Image();    
  //   img.src = this.props.photoGalleryByWeek && this.props.photoGalleryByWeek[this.state.currentImageIndex].url;

  //   img.onload = () => {
  //     // Clear the canvas before drawing the image
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);

  //     // Set the canvas dimensions to match the image aspect ratio if needed
  //     const aspectRatio = img.width / img.height;
  //     const canvasHeight = canvas.width / aspectRatio;
  //     canvas.height = canvasHeight; // Adjust canvas height to match image

  //     // Draw the image on the canvas
  //     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  //     // Draw any existing shapes on top of the image
  //     this.redrawShapes();
  //   };
  //   console.log('img',img);
    

  //   img.onerror = () => {
  //     console.error("Failed to load the image");
  //   };
  // };

  redrawCanvas = () => {
    const canvas = this.canvasRef.current;
    if (!canvas) {
      console.error("Canvas ref is not set or is null");
      return;
    }
    const ctx = canvas.getContext('2d');
    const img = new Image();
  
    // Set the crossOrigin property to 'anonymous' to avoid CORS issues
    img.crossOrigin = 'anonymous';
  
    img.src = this.props.photoGalleryByWeek && this.props.photoGalleryByWeek[this.state.currentImageIndex].url;
  
    img.onload = () => {
      // Clear the canvas before drawing the image
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      // Set the canvas dimensions to match the image aspect ratio if needed
      const aspectRatio = img.width / img.height;
      const canvasHeight = canvas.width / aspectRatio;
      canvas.height = canvasHeight; // Adjust canvas height to match image
  
      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
      // Draw any existing shapes on top of the image
      this.redrawShapes();
    };
  
    img.onerror = () => {
      console.error("Failed to load the image");
    };
  };
  

  redrawShapes = () => {
    this.setState({isImageLoading: true});
    if (!this.canvasRef.current) {
      console.error("Canvas ref is not set or is null");
      return;
    }
    const ctx = this.canvasRef.current.getContext('2d');
    const { shapes } = this.state;

    shapes.forEach(({ startX, startY, endX, endY, shapeType }) => {
      ctx.fillStyle = 'black';
      if (shapeType === 'box') {
        ctx.fillRect(startX, startY, endX - startX, endY - startY);
      } else if (shapeType === 'circle') {
        const radius = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2)) / 2;
        ctx.beginPath();
        ctx.arc((startX + endX) / 2, (startY + endY) / 2, radius, 0, 2 * Math.PI);
        ctx.fill();
      }
    });
    this.setState({isImageLoading: false});
  };

  saveImage = () => {
    const canvas = this.canvasRef.current;
  
    if (!canvas) {
      console.error("Canvas ref is not set");
      return;
    }
  
    // toBlob is asynchronous, so we pass a callback function to handle the result
    canvas.toBlob((blob) => {
      if (blob) {
        // You can log the blob, or upload it to the server or use it as needed
        console.log('Blob', blob);
  
        // Example: To create a downloadable link for the image
        const url = URL.createObjectURL(blob);
        console.log('url',url);
        
        const link = document.createElement('a');
        link.href = url;
        link.download = 'edited_image.png'; // You can name the file
        link.click();
  
        // Clean up the object URL after download
        URL.revokeObjectURL(url);
      } else {
        console.error("Failed to convert canvas to blob");
      }
    }, 'image/png', 1); // 'image/png' format and quality of 1 (highest)
  };
  
  resetImage = () => {
    this.setState({ shapes: [] }, () => {
      this.redrawCanvas();
    });
  };

  drawImageOnCanvas = () => {
    this.setState({isImageLoading: true});
    const { selectedImage } = this.state;
    if (!selectedImage) return;

    const canvas = this.canvasRef.current;
    if (!canvas) {
      console.error("Canvas ref is not set");
      return;
    }

    const ctx = canvas.getContext("2d");

    const image = new Image();
    console.log('image',image);
    
    image.src = selectedImage.url;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height); // Draw the new image
    };
    this.setState({isImageLoading: false});

  };

  deleteImage = async () => {
    let image = this.state.image.desc;
    let userId = this.props.match.params.id;
    let quatar = this.state.quatar;
    let studyId = localStorage.getItem("studyId");
    console.log('image',image, studyId, userId, quatar);
    let deleteImage = await this.props.deleteParadeImage(image, studyId, quatar, userId);
    console.log('deleteImage',deleteImage);
    if (deleteImage.data) {
      notifyUser("Image deleted successfully", "success");
      this.handleCancel();
      await this.componentDidMount();
    } else {
      notifyUser("Something went wrong", "error");
    }
  }

  goToNewMed = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/new-medication/" + patientId);
  }

  render() {
    const {
      isImageLoading,
      selectedImage,
      visibleModal,
      fileList,
      submitted,
      selectedCol,
      photoGallery,
      easiScores,
      selectedMonth,
      selectedMonthNih,
      loading,
      selectedWeek,
      paradeUser,
      currentImageIndex,
      isModalVisible,
      image,
      rotationAngles,
      monthnih,
      isDisable,
      imgWidth,
      imgHeight
    } = this.state;

    let monthnu = this.state.monthnih;

    const patientData = this.props.patientData && this.props.patientData[0];


    const { photoGalleryByWeek} = this.props;

    let week = this.state.week;
    let quatar = this.state.quatar;
    let weekNumber;

    switch (week) {
      case 1:
        weekNumber = 0;
        break;
      case 3:
        weekNumber = 2;
        break;
      case 5:
        weekNumber = 4;
        break;
      case 7:
        weekNumber = 6;
        break;
      case 9:
        weekNumber = 8;
        break;
      default:
        weekNumber = 0; // handle the case when week is not 1, 2, 3, or 4
        break;
    }

    let monthNumber;

    switch (monthnu) {
      case 1:
        monthNumber = 0;
        break;
      case 2:
        monthNumber = 1;
        break;
      case 3:
        monthNumber = 2;
        break;
      case 4:
        monthNumber = 3;
        break;
      case 5:
        monthNumber = 4;
        break;
      case 6:
        monthNumber = 5;
        break;
      case 7:
        monthNumber = 6;
        break;
      default:
        monthNumber = 0; // handle the case when week is not 1, 2, 3, or 4
        break;
    }

    const studyId = localStorage.getItem("studyId");

    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }

    // Patient Start End time
    const notificationEndDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate;
    const notificationTimezone = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationTimezone;
    const endDateInTimezone = moment.tz(notificationEndDate, notificationTimezone);
    let formattedTimeEnd = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate) {
      formattedTimeEnd = endDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeEnd = "";
    }
    const formattedTimeEndData = formattedTimeEnd;

    const notificationStartDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate;
    const startDateInTimezone = moment.tz(notificationStartDate, notificationTimezone);
    let formattedTimeStart = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate) {
      formattedTimeStart = startDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeStart = "";
    }
    const formattedTimeStartData = formattedTimeStart;
    // Patient Start End time end

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">

            <PageHeader
              className="site-header-title"
              title={
                this.state.data && paradeUser && studyId === "3" ? (
                  <>
                    {this.state.data.title} - <span className="patid">{paradeUser[0].patientId}</span>
                  </>
                ) : (
                  this.state.data && this.state.data.title
                )
              }
              extra={[
                <Button onClick={() => this.goToPatientProgress()}>
                    Back
                  </Button> ,]}
            />
            <Row gutter={16}>
              {
                studyId === "3" ? ""
                :
                <Col xs={24} sm={12} md={6} xl={6}>
                  <div className="white-box user-section">
                    <div className="user-profile">
                      <Link to={"/patient-progress/" + this.props.match.params.id} >
                        <Avatar size={84}>
                          {this.props.patientData
                            ? this.props.patientData[0].firstName
                              .toUpperCase()
                              .charAt(0) +
                            this.props.patientData[0].lastName
                              .toUpperCase()
                              .charAt(0)
                            : ""}
                        </Avatar>
                      </Link>
                      <div className="profile-details">
                        <Link to={"/patient-progress/" + this.props.match.params.id} >
                          <h3>
                            {this.props.patientData
                              ? this.props.patientData[0].firstName +
                              " " +
                              this.props.patientData[0].lastName
                              : ""}
                          </h3>
                        </Link>
                        {
                          studyId ==1 ?
                          <button className="btn" onClick={() => this.goToChat()}>
                            Chat Now
                          </button>
                          :
                          ""
                        }
                      </div>
                    </div>
                    <div className="user-details">
                      <List
                        style={{ marginTop: "40px" }}
                        itemLayout="horizontal"
                        dataSource={this.props.patientData}
                        renderItem={(patient) => (
                          <>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Study:
                              </Typography.Text>{" "}
                              {patient && patient.title}
                            </List.Item>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Email:
                              </Typography.Text>{" "}
                              {patient.email}
                            </List.Item>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Date of Birth:
                              </Typography.Text>{" "}
                              {/* {patient.dateOfBirth
                                ? moment(new Date(Number(patient.dateOfBirth))).format(
                                  "DD-MM-YYYY"
                                )
                                : "N/A"} */}
                                {finalDate}
                            </List.Item>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Age:
                              </Typography.Text>{" "}
                              {patient.age ? patient.age + " years" : "N/A"}
                            </List.Item>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Phone Number:
                              </Typography.Text>{" "}
                              {"(" +
                                patient.phoneCode +
                                ") " +
                                patient.phoneNumber}
                            </List.Item>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Member Status:
                              </Typography.Text>{" "}
                              {patient.userScreeningStatus.replace("_", " ")}
                            </List.Item>
                            {
                              studyId == 2 ?
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Patient's TimeZone:
                                </Typography.Text>{" "}
                                  {patient.notificationTimezone}
                              </List.Item>
                              : ""
                            }
                            {
                              studyId == 2 ?
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Notifications Time:
                                </Typography.Text>{" "}
                                  {formattedTimeStartData} {!formattedTimeStartData && !formattedTimeEndData ? "" : "to"} {formattedTimeEndData}
                              </List.Item>
                              : 
                              ""
                            }
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Registered Date:
                              </Typography.Text>{" "}
                              {patient.createdDate
                                ? moment(patient.createdDate)
                                  .format(dateFormathh)
                                : "N/A"}
                            </List.Item>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </Col>
              }
              

              <Col xs={24} sm={studyId == "3" ? 24 : 12} md={studyId == "3" ? 24 :18} xl={studyId == "3" ? 24 :18}>
              {
                studyId === "3" ?
                <div className="parade">
                  <ButtonGroup className="horizontal-button-tabs flare-photos">
                    <Button
                      className={"active"}
                      // onClick={() => this.goToPhotoGallery()}
                      >
                      Photogallery
                    </Button>
                    <Button
                      className={"inactive"}
                      onClick={() => this.gotToNewRash()}
                    >
                      New Rash
                    </Button>
                  </ButtonGroup>
                </div> : ""
              }
                {/* PHOTOGALLERY START */}
                {studyId == 1 ?
                  <ButtonGroup className="horizontal-button-tabs">
                    <Button
                    className={selectedMonth === 1 ? "active" : ""}
                    onClick={() => this.getPhotos(1)}
                    >
                      Month 1
                    </Button>
                    
                    <Button
                      className={selectedMonth === 2 ? "active" : ""}
                      onClick={() => this.getPhotos(2)}
                    >
                      Month 2
                    </Button>
                    <Button
                      className={selectedMonth === 3 ? "active" : ""}
                      onClick={() => this.getPhotos(3)}
                    >
                      Month 3
                    </Button>
                    <Button
                      className={selectedMonth === 4 ? "active" : ""}
                      onClick={() => this.getPhotos(4)}
                    >
                      Month 4
                    </Button>
                    <Button
                      className={selectedMonth === 5 ? "active" : ""}
                      onClick={() => this.getPhotos(5)}
                    >
                      Month 5
                    </Button>
                    <Button
                      className={selectedMonth === 6 ? "active" : ""}
                      onClick={() => this.getPhotos(6)}
                    >
                      Month 6
                    </Button>
                  </ButtonGroup> 
                  : studyId == 2 ?
                  <ButtonGroup className="horizontal-button-tabs spaces">
                    <Button
                      className={selectedWeek === 1 ? "active" : ""}
                      onClick={() => this.getPhotosByWeek(1)}
                    >
                      Week 0
                    </Button>  
                    <Button
                      className={selectedWeek === 3 ? "active" : ""}
                      onClick={() => this.getPhotosByWeek(3)}
                    >
                      Week 2
                    </Button>  
                    <Button
                      className={selectedWeek === 5 ? "active" : ""}
                      onClick={() => this.getPhotosByWeek(5)}
                    >
                      Week 4
                    </Button>  
                    <Button
                      className={selectedWeek === 7 ? "active" : ""}
                      onClick={() => this.getPhotosByWeek(7)}
                    >
                      Week 6
                    </Button>
                    <Button
                      className={selectedWeek === 9 ? "active" : ""}
                      onClick={() => this.getPhotosByWeek(9)}
                    >
                      Week 8
                    </Button> 
                  </ButtonGroup>
                  :  studyId == 4 ?
                  <ButtonGroup className="horizontal-button-tabs spaces">
                    <Button
                      className={selectedMonthNih === 1 ? "active" : ""}
                      onClick={() => this.getPhotosByMonth(1)}
                    >
                      Month 0
                    </Button>  
                    <Button
                      className={selectedMonthNih === 2 ? "active" : ""}
                      onClick={() => this.getPhotosByMonth(2)}
                    >
                      Month 1
                    </Button>  
                    <Button
                      className={selectedMonthNih === 3 ? "active" : ""}
                      onClick={() => this.getPhotosByMonth(3)}
                    >
                      Month 2
                    </Button>  
                    <Button
                      className={selectedMonthNih === 4 ? "active" : ""}
                      onClick={() => this.getPhotosByMonth(4)}
                    >
                      Month 3
                    </Button>
                    <Button
                      className={selectedMonthNih === 5 ? "active" : ""}
                      onClick={() => this.getPhotosByMonth(5)}
                    >
                      Month 4
                    </Button>
                    <Button
                      className={selectedMonthNih === 6 ? "active" : ""}
                      onClick={() => this.getPhotosByMonth(6)}
                    >
                      Month 5
                    </Button>
                    <Button
                      className={selectedMonthNih === 7 ? "active" : ""}
                      onClick={() => this.getPhotosByMonth(7)}
                    >
                      Month 6
                    </Button>
                  </ButtonGroup>
                    :
                    <ButtonGroup className="horizontal-button-tabs spaces">
                      <Button
                        className={selectedWeek === 1 ? "active" : ""}
                        onClick={() => this.getPhotosParade(1)}
                      >
                        Quarter 1
                      </Button>  
                      <Button
                        className={selectedWeek === 2 ? "active" : ""}
                        onClick={() => this.getPhotosParade(2)}
                      >
                        Quarter 2
                      </Button>  
                      <Button
                        className={selectedWeek === 3 ? "active" : ""}
                        onClick={() => this.getPhotosParade(3)}
                      >
                        Quarter 3
                      </Button>  
                      <Button
                        className={selectedWeek === 4 ? "active" : ""}
                        onClick={() => this.getPhotosParade(4)}
                      >
                        Quarter 4
                      </Button>
                    </ButtonGroup>
                }
                <Modal
                  title="Upload Photo"
                  visible={visibleModal}
                  onOk={this.handleAdd}
                  onCancel={this.handleCancel}
                  okText="Add"
                  okButtonProps={{ disabled: isDisable }}
                >
                  <Form ref={this.formRef} name="upload_form" className="upload-photo-modal">
                    <Form.Item
                      name="title"
                      label="Photo/Body part name"
                      rules={[{ required: true, message: 'Please input photo/Body part name!' }]}
                    >
                      <Input placeholder="Photo/Body part name" maxLength={50}/>
                    </Form.Item>
                    <Form.Item
                      name="photoDate"
                      label="Date of Photograph"
                      rules={[{ required: true, message: 'Please select date of photograph!' }]}
                      >
                      <DatePicker 
                        onChange={this.onDateChange} 
                        disabledDate={this.disabledFutureDates}/>
                    </Form.Item>
                    <Form.Item
                      name="image"
                      label="Upload Image"
                      rules={[{ required: true, message: 'Please upload an image!' }]}
                    >
                      <Upload
                        accept=".jpg,.jpeg"
                        beforeUpload={() => false} // Prevent automatic upload
                        onChange={this.handleFileChange}
                        fileList={fileList}
                        className="bulk-import-upload photogallery-image-upload"
                        onRemove={this.handleFileRemove}
                      >
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item>
                  </Form>
                </Modal>
                {studyId == "1" ? 
                
                // VERTICAL TAB FOR STUDY 1
                  <Tabs
                    onChange={this.handleSelect}
                    tabPosition="left"
                    className="vertical-data-tabs"
                  >
                    <TabPane tab="HEAD IMAGE" key="HEAD" type="card">
                      <Spin spinning={loading}>
                        {!this.state.loading && (
                          <Card
                            title={
                              "Month " +
                              this.state.month +
                              " " +
                              this.state.partType
                            }
                            // extra={"Date: 14/02/2021"}
                            style={{ marginBottom: "8px", padding: "0 16px" }}
                            className="week-section"
                          >
                            
                            {photoGallery && photoGallery.length > 0 ?
                              <Row align="bottom" type="flex" gutter={16}>
                                <Col span={8} offset={17}>
                                  <Button onClick={() => this.downloadImage()}>Download Images</Button>
                                </Col>
                              </Row>
                              : ""
                            }
                            <Carousel
                              arrows={true}
                              prevArrow={<LeftOutlined />}
                              nextArrow={<RightOutlined />}
                              className="images-slider"
                              slidesPerRow={3}
                              swipeToSlide={true}
                              touchThreshold={20}
                              focusOnSelect={true}
                              centerMode={true}
                              ref={(carousel) => (this.nav = carousel)}
                              draggable={true}
                              autoplay={true}
                              vertical
                              effect="fade"
                            >
                              {photoGallery &&
                                photoGallery.map((img) => {
                                  return (
                                    <div>
                                      <img
                                        key={img.id}
                                        src={img.url}
                                        alt={img.desc}
                                        onClick={() => this.showModal(img)}
                                        loading="lazy"
                                      />
                                      <Typography.Text>
                                        {img.desc}
                                      </Typography.Text>
                                      <div>
                                        <Modal
                                          centered
                                          width={450}
                                          className="photo-zoom-popup"
                                          title={this.state.image.desc}
                                          visible={this.state.isModalVisible}
                                          onCancel={this.handleCancel}
                                          footer={[<></>]}
                                        >
                                          <TransformWrapper
                                            defaultScale={1}
                                            defaultPositionX={1}
                                            defaultPositionY={1}
                                          >
                                            {({ zoomIn, zoomOut }) => (
                                              <>
                                                <PlusCircleFilled
                                                  size={30}
                                                  onClick={zoomIn}
                                                />
                                                <MinusCircleFilled
                                                  size={20}
                                                  onClick={zoomOut}
                                                />
                                                <TransformComponent>
                                                  <img
                                                    width={400}
                                                    src={this.state.image.url}
                                                    alt={img.desc}
                                                    loading="lazy"
                                                  />
                                                </TransformComponent>
                                              </>
                                            )}
                                          </TransformWrapper>
                                        </Modal>
                                      </div>
                                    </div>
                                  );
                                })}
                            </Carousel>
                            <div className="easi-form-data">
                              {easiScores ? (
                                <Form name="easi-form" layout="inline">
                                  <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="erthyema"
                                        label="Erthyema"
                                        initialValue={
                                          easiScores.erythema
                                            ? easiScores.erythema
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="edema"
                                        label="Edema/population"
                                        initialValue={
                                          easiScores.edema ? easiScores.edema : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="excoriation"
                                        label="Excoriation"
                                        initialValue={
                                          easiScores.excoriation
                                            ? easiScores.excoriation
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="ascoscope"
                                        label="Lichenification"
                                        initialValue={
                                          easiScores.ascoScope
                                            ? easiScores.ascoScope
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form>
                              ) : (
                                ""
                              )}
                            </div>
                          </Card>
                        )}
                      </Spin>
                    </TabPane>
                    <TabPane tab="UPPER EXTREMITY" key="UPPER_EXTREMITY">
                      <Spin spinning={loading}>
                        {!this.state.loading && (
                          <Card
                            title={
                              "Month " +
                              this.state.month +
                              " " +
                              this.state.partType
                            }
                            //extra={"Date: 14/02/2021"}
                            style={{ marginBottom: "8px", padding: "0 16px" }}
                            className="week-section"
                          >
                            {photoGallery && photoGallery.length > 0 ?
                              <Row>
                                <Col span={8} offset={17}>
                                  <Button onClick={() => this.downloadImage()}>Download Images</Button>
                                </Col>
                              </Row>
                              : ""
                            }
                            <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={true}
                                vertical
                                effect="fade"
                              >
                                {photoGallery &&
                                  photoGallery.map((img) => {
                                    return (
                                      <div>
                                        <img
                                          key={img.id}
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>
                                          {img.desc}
                                        </Typography.Text>
                                        <div>
                                          <Modal
                                            centered
                                            width={450}
                                            className="photo-zoom-popup"
                                            title={this.state.image.desc}
                                            visible={this.state.isModalVisible}
                                            onCancel={this.handleCancel}
                                            footer={[<></>]}
                                          >
                                            <TransformWrapper
                                              defaultScale={1}
                                              defaultPositionX={1}
                                              defaultPositionY={1}
                                            >
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <PlusCircleFilled
                                                    size={30}
                                                    onClick={zoomIn}
                                                  />
                                                  <MinusCircleFilled
                                                    size={20}
                                                    onClick={zoomOut}
                                                  />
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={this.state.image.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                          </Modal>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </Carousel>
                            </div>
                            <div className="easi-form-data">
                              {easiScores ? (
                                <Form name="easi-form" layout="inline">
                                  <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="erthyema"
                                        label="Erthyema"
                                        initialValue={
                                          easiScores.erythema
                                            ? easiScores.erythema
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="edema"
                                        label="Edema"
                                        initialValue={
                                          easiScores.edema ? easiScores.edema : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="excoriation"
                                        label="Excoriation"
                                        initialValue={
                                          easiScores.excoriation
                                            ? easiScores.excoriation
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="ascoscope"
                                        label="Asco Scope"
                                        initialValue={
                                          easiScores.ascoScope
                                            ? easiScores.ascoScope
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form>
                              ) : (
                                ""
                              )}
                            </div>
                          </Card>
                        )}
                      </Spin>
                    </TabPane>
                    <TabPane tab="LOWER EXTREMITY" key="LOWER_EXTREMITY">
                      <Spin spinning={loading}>
                        {!this.state.loading && (
                          <Card
                            title={
                              "Month " +
                              this.state.month +
                              " " +
                              this.state.partType
                            }
                            // extra={"Date: 14/02/2021"}
                            style={{ marginBottom: "8px", padding: "0 16px" }}
                            className="week-section"
                          >
                            {photoGallery && photoGallery.length > 0 ?
                              <Row>
                                <Col span={8} offset={17}>
                                  <Button onClick={() => this.downloadImage()}>Download Images</Button>
                                </Col>
                              </Row>
                              : ""
                            }
                            <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={true}
                                vertical
                                effect="fade"
                              >
                                {photoGallery &&
                                  photoGallery.map((img) => {
                                    return (
                                      <div>
                                        <img
                                          key={img.id}
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>
                                          {img.desc}
                                        </Typography.Text>
                                        <div>
                                          <Modal
                                            centered
                                            width={450}
                                            className="photo-zoom-popup"
                                            title={this.state.image.desc}
                                            visible={this.state.isModalVisible}
                                            onCancel={this.handleCancel}
                                            footer={[<></>]}
                                          >
                                            <TransformWrapper
                                              defaultScale={1}
                                              defaultPositionX={1}
                                              defaultPositionY={1}
                                            >
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <PlusCircleFilled
                                                    size={30}
                                                    onClick={zoomIn}
                                                  />
                                                  <MinusCircleFilled
                                                    size={20}
                                                    onClick={zoomOut}
                                                  />
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={this.state.image.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                          </Modal>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </Carousel>
                            </div>
                            <div className="easi-form-data">
                              {easiScores ? (
                                <Form name="easi-form" layout="inline">
                                  <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="erthyema"
                                        label="Erthyema"
                                        initialValue={
                                          easiScores.erythema
                                            ? easiScores.erythema
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="edema"
                                        label="Edema"
                                        initialValue={
                                          easiScores.edema ? easiScores.edema : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="excoriation"
                                        label="Excoriation"
                                        initialValue={
                                          easiScores.excoriation
                                            ? easiScores.excoriation
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="ascoscope"
                                        label="Asco Scope"
                                        initialValue={
                                          easiScores.ascoScope
                                            ? easiScores.ascoScope
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form>
                              ) : (
                                ""
                              )}
                            </div>
                          </Card>
                        )}
                      </Spin>
                    </TabPane>
                    <TabPane tab="TRUNK IMAGE" key="TRUNK">
                      <Spin spinning={loading}>
                        {!this.state.loading && (
                          <Card
                            title={
                              "Month " +
                              this.state.month +
                              " " +
                              this.state.partType
                            }
                            //extra={"Date: 14/02/2021"}
                            style={{ marginBottom: "8px", padding: "0 16px" }}
                            className="week-section"
                          >
                            {photoGallery && photoGallery.length > 0 ?
                              <Row>
                                <Col span={8} offset={17}>
                                  <Button onClick={() => this.downloadImage()}>Download Images</Button>
                                </Col>
                              </Row>
                              : ""
                            }
                            <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={true}
                                vertical
                                effect="fade"
                              >
                                {photoGallery &&
                                  photoGallery.map((img) => {
                                    return (
                                      <div>
                                        <img
                                          key={img.id}
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>
                                          {img.desc}
                                        </Typography.Text>
                                        <div>
                                          <Modal
                                            centered
                                            width={450}
                                            className="photo-zoom-popup"
                                            title={this.state.image.desc}
                                            visible={this.state.isModalVisible}
                                            onCancel={this.handleCancel}
                                            footer={[<></>]}
                                          >
                                            <TransformWrapper
                                              defaultScale={1}
                                              defaultPositionX={1}
                                              defaultPositionY={1}
                                            >
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <PlusCircleFilled
                                                    size={30}
                                                    onClick={zoomIn}
                                                  />
                                                  <MinusCircleFilled
                                                    size={20}
                                                    onClick={zoomOut}
                                                  />
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={this.state.image.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                          </Modal>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </Carousel>
                            </div>
                            <div className="easi-form-data">
                              {easiScores ? (
                                <Form name="easi-form" layout="inline">
                                  <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="erthyema"
                                        label="Erthyema"
                                        initialValue={
                                          easiScores.erythema
                                            ? easiScores.erythema
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="edema"
                                        label="Edema"
                                        initialValue={
                                          easiScores.edema ? easiScores.edema : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="excoriation"
                                        label="Excoriation"
                                        initialValue={
                                          easiScores.excoriation
                                            ? easiScores.excoriation
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="ascoscope"
                                        label="Asco Scope"
                                        initialValue={
                                          easiScores.ascoScope
                                            ? easiScores.ascoScope
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form>
                              ) : (
                                ""
                              )}
                            </div>
                          </Card>
                        )}
                      </Spin>
                    </TabPane>

                    <TabPane tab="All IMAGES" key="ALL">
                      <Spin spinning={loading}>
                        {!this.state.loading && (
                          <Card
                            title={
                              "Month " +
                              this.state.month +
                              " " +
                              this.state.partType
                            }
                            //extra={"Date: 14/02/2021"}
                            style={{ marginBottom: "8px", padding: "0 16px" }}
                            className="week-section"
                          >
                            {photoGallery && photoGallery.length > 0 ?
                              <Row>
                                <Col span={8} offset={17}>
                                  <Button onClick={() => this.downloadImage()}>Download Images</Button>
                                </Col>
                              </Row>
                              : ""
                            }
                            <div className="images-container">
                              {/* <Row> */}

                              {/* </Row> */}
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={true}
                                vertical
                                effect="fade"
                              >
                                {photoGallery &&
                                  photoGallery.map((img) => {
                                    return (
                                      <div>
                                        <img
                                          key={img.id}
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>
                                          {img.desc}
                                        </Typography.Text>
                                        <div>
                                          <Modal
                                            centered
                                            width={450}
                                            className="photo-zoom-popup"
                                            title={this.state.image.desc}
                                            visible={this.state.isModalVisible}
                                            onCancel={this.handleCancel}
                                            footer={[<></>]}
                                          >
                                            <TransformWrapper
                                              defaultScale={1}
                                              defaultPositionX={1}
                                              defaultPositionY={1}
                                            >
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <PlusCircleFilled
                                                    size={30}
                                                    onClick={zoomIn}
                                                  />
                                                  <MinusCircleFilled
                                                    size={20}
                                                    onClick={zoomOut}
                                                  />
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={this.state.image.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                          </Modal>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </Carousel>
                            </div>
                            <div className="easi-form-data">
                              {easiScores ? (
                                <Form name="easi-form" layout="inline">
                                  <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="erthyema"
                                        label="Erthyema"
                                        initialValue={
                                          easiScores.erythema
                                            ? easiScores.erythema
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="edema"
                                        label="Edema"
                                        initialValue={
                                          easiScores.edema ? easiScores.edema : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="excoriation"
                                        label="Excoriation"
                                        initialValue={
                                          easiScores.excoriation
                                            ? easiScores.excoriation
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <Form.Item
                                        name="ascoscope"
                                        label="Asco Scope"
                                        initialValue={
                                          easiScores.ascoScope
                                            ? easiScores.ascoScope
                                            : ""
                                        }
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form>
                              ) : (
                                ""
                              )}
                            </div>
                          </Card>
                        )}
                      </Spin>
                    </TabPane>
                  </Tabs>
                  
                // VERTICAL TAB FOR STUDY 1 END
                : studyId == "2" ?
                // VERTICAL TAB FOR STUDY 2
                <Tabs
                  onChange={this.handleSelectWeek}
                  tabPosition="left"
                  className="vertical-data-tabs"
                >

                  <TabPane tab="Section 1" key="section1" type="card">
                    <Spin spinning={loading}>

                      {!this.state.loading && (
                        
                        <Card
                          title={
                            "Week " +
                            weekNumber +
                            " " +
                            this.state.sectionPart
                          }
                          // extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row align="bottom" type="flex" gutter={16} className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                            <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  width={450}
                                  className="photo-zoom-popup"
                                  // title={this.state.image.desc}
                                  title={"Week " +
                                    weekNumber +
                                    " " +
                                    this.state.sectionPart}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                        photoGalleryByWeek.map((img, index) => (
                                          <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={img.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                      style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                          </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                        </Card>
                       )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="Section 2" key="section2">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Week " +
                            weekNumber +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  width={450}
                                  className="photo-zoom-popup"
                                  // title={this.state.image.desc}
                                  title={"Week " +
                                    weekNumber +
                                    " " +
                                    this.state.sectionPart}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                        photoGalleryByWeek.map((img, index) => (
                                          <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={img.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                      style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                          </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="Section 3" key="section3">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Week " +
                            weekNumber +
                            " " +
                            this.state.sectionPart
                          }
                          // extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  width={450}
                                  className="photo-zoom-popup"
                                  // title={this.state.image.desc}
                                  title={"Week " +
                                    weekNumber +
                                    " " +
                                    this.state.sectionPart}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                        photoGalleryByWeek.map((img, index) => (
                                          <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={img.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                      style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                          </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="Section 4" key="section4">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Week " +
                            weekNumber +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >

                                {/* {photoGallery &&
                                  photoGallery.map((img) => {
                                    return (
                                      <div>
                                        <img
                                          key={img.url}
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img)}
                                          //loading="lazy"
                                        />
                                        <Typography.Text>
                                          {img.desc}
                                        </Typography.Text>
                                        
                                      </div>
                                    );
                                  })} */}
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  width={450}
                                  className="photo-zoom-popup"
                                  // title={this.state.image.desc}
                                  title={"Week " +
                                    weekNumber +
                                    " " +
                                    this.state.sectionPart}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                        photoGalleryByWeek.map((img, index) => (
                                          <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={img.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                      style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                          </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>

                  <TabPane tab="Section 5" key="section5">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Week " +
                            weekNumber +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >

                                {/* {photoGallery &&
                                  photoGallery.map((img) => {
                                    return (
                                      <div>
                                        <img
                                          key={img.url}
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img)}
                                          //loading="lazy"
                                        />
                                        <Typography.Text>
                                          {img.desc}
                                        </Typography.Text>
                                        
                                      </div>
                                    );
                                  })} */}
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  width={450}
                                  className="photo-zoom-popup"
                                  // title={this.state.image.desc}
                                  title={"Week " +
                                    weekNumber +
                                    " " +
                                    this.state.sectionPart}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                        photoGalleryByWeek.map((img, index) => (
                                          <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={img.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                      style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                          </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="All Images" key="allImages">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Week " +
                            weekNumber +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  width={450}
                                  className="photo-zoom-popup"
                                  // title={this.state.image.desc}
                                  title={"Week " +
                                    weekNumber +
                                    " " +
                                    this.state.sectionPart}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                        photoGalleryByWeek.map((img, index) => (
                                          <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={img.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                      style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                          </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                </Tabs>
                // VERTICAL TAB FOR STUDY 2 END
                : studyId == "3" ?
                // VERTICAL TAB FOR STUDY 3
                <Tabs
                  onChange={this.handleSelectParade}
                  tabPosition="left"
                  className="vertical-data-tabs">

                  <TabPane tab="Right elbow crease" key="rightElbowCrease" type="card">
                    <Spin spinning={loading}>

                      {!this.state.loading && (
                        
                        <Card
                          title={
                            "Quarter " +
                              quatar +
                              " " +
                              this.state.sectionPart
                          }
                          // extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row align="bottom" type="flex" gutter={16} className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageOhsu()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                            <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                        <div>
                                          <Modal
                                            centered
                                            width={450}
                                            className="photo-zoom-popup"
                                            title={<><span className="patid">{patientData.patientId}</span>{" - Week " + quatar + " " + this.state.sectionPart}</>}
                                            visible={isModalVisible}
                                            onCancel={this.handleCancel}
                                            footer={null}
                                          >
                                            <p style={{marginBottom: "5px", marginTop: "-15px"}}>To add a black box to a specific part of the image click and drag the mouse diagonally over the area you want to hide.</p>
                                            <div className="image-edit">
                                              <Button onClick={this.resetImage}>Reset Image</Button>
                                              <Button onClick={this.saveImage}>Save Image</Button>
                                              <Button onClick={this.deleteImage}>Delete Image</Button>
                                            </div>
                                            <Spin spinning={isImageLoading} tip="Loading image...">
                                            <TransformWrapper 
                                              defaultScale={1} 
                                              defaultPositionX={0} 
                                              defaultPositionY={0}
                                              pan={{ disabled: true }} >
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    {/* <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} /> */}
                                                  </div>
                                                  <TransformComponent>
                                                    <div key={index}>
                                                      <canvas
                                                        ref={this.canvasRef}
                                                        width={imgWidth}
                                                        height={imgHeight}
                                                        onMouseDown={this.handleMouseDown}
                                                        onMouseMove={this.handleMouseMove}
                                                        onMouseUp={this.handleMouseUp}
                                                      />
                                                      <Typography.Text>{img.desc}</Typography.Text>
                                                    </div>
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            </Spin>
                                          </Modal>
                                        </div>
                                      </div>
                                    ))}
                              </Carousel>
                            </div>
                        </Card>
                       )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="Left elbow crease" key="leftElbowCrease">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Quarter " +
                            quatar +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageOhsu()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                            <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                        <div>
                                          <Modal
                                            centered
                                            width={450}
                                            className="photo-zoom-popup"
                                            title={<><span className="patid">{patientData.patientId}</span>{" - Week " + quatar + " " + this.state.sectionPart}</>}
                                            visible={isModalVisible}
                                            onCancel={this.handleCancel}
                                            footer={null}
                                          >
                                            <p style={{marginBottom: "5px", marginTop: "-15px"}}>To add a black box to a specific part of the image click and drag the mouse diagonally over the area you want to hide.</p>
                                            <div className="image-edit">
                                              <Button onClick={this.resetImage}>Reset Image</Button>
                                              <Button onClick={this.saveImage}>Save Image</Button>
                                              <Button onClick={this.deleteImage}>Delete Image</Button>
                                            </div>
                                            <Spin spinning={isImageLoading} tip="Loading image...">
                                            <TransformWrapper 
                                              defaultScale={1} 
                                              defaultPositionX={0} 
                                              defaultPositionY={0}
                                              pan={{ disabled: true }} >
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    {/* <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} /> */}
                                                  </div>
                                                  <TransformComponent>
                                                    <div key={index}>
                                                      <canvas
                                                        ref={this.canvasRef}
                                                        width={imgWidth}
                                                        height={imgHeight}
                                                        onMouseDown={this.handleMouseDown}
                                                        onMouseMove={this.handleMouseMove}
                                                        onMouseUp={this.handleMouseUp}
                                                      />
                                                      <Typography.Text>{img.desc}</Typography.Text>
                                                    </div>
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            </Spin>
                                          </Modal>
                                        </div>
                                      </div>
                                    ))}
                              </Carousel>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="Crease behind right knee" key="creaseBehindRightKnee">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Quarter " +
                            quatar +
                            " " +
                            this.state.sectionPart
                          }
                          // extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageOhsu()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                        <div>
                                          <Modal
                                            centered
                                            width={450}
                                            className="photo-zoom-popup"
                                            title={<><span className="patid">{patientData.patientId}</span>{" - Week " + quatar + " " + this.state.sectionPart}</>}
                                            visible={isModalVisible}
                                            onCancel={this.handleCancel}
                                            footer={null}
                                          >
                                            <p style={{marginBottom: "5px", marginTop: "-15px"}}>To add a black box to a specific part of the image click and drag the mouse diagonally over the area you want to hide.</p>
                                            <div className="image-edit">
                                              <Button onClick={this.resetImage}>Reset Image</Button>
                                              <Button onClick={this.saveImage}>Save Image</Button>
                                              <Button onClick={this.deleteImage}>Delete Image</Button>
                                            </div>
                                            <Spin spinning={isImageLoading} tip="Loading image...">
                                            <TransformWrapper 
                                              defaultScale={1} 
                                              defaultPositionX={0} 
                                              defaultPositionY={0}
                                              pan={{ disabled: true }} >
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    {/* <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} /> */}
                                                  </div>
                                                  <TransformComponent>
                                                    <div key={index}>
                                                      <canvas
                                                        ref={this.canvasRef}
                                                        width={imgWidth}
                                                        height={imgHeight}
                                                        onMouseDown={this.handleMouseDown}
                                                        onMouseMove={this.handleMouseMove}
                                                        onMouseUp={this.handleMouseUp}
                                                      />
                                                      <Typography.Text>{img.desc}</Typography.Text>
                                                    </div>
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            </Spin>
                                          </Modal>
                                        </div>
                                      </div>
                                    ))}
                              </Carousel>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="Crease behind left knee" key="creaseBehindLeftKnee">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Quarter " +
                            quatar +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageOhsu()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                        <div>
                                          <Modal
                                            centered
                                            width={450}
                                            className="photo-zoom-popup"
                                            title={<><span className="patid">{patientData.patientId}</span>{" - Week " + quatar + " " + this.state.sectionPart}</>}
                                            visible={isModalVisible}
                                            onCancel={this.handleCancel}
                                            footer={null}
                                          >
                                            <p style={{marginBottom: "5px", marginTop: "-15px"}}>To add a black box to a specific part of the image click and drag the mouse diagonally over the area you want to hide.</p>
                                            <div className="image-edit">
                                              <Button onClick={this.resetImage}>Reset Image</Button>
                                              <Button onClick={this.saveImage}>Save Image</Button>
                                              <Button onClick={this.deleteImage}>Delete Image</Button>
                                            </div>
                                            <Spin spinning={isImageLoading} tip="Loading image...">
                                            <TransformWrapper 
                                              defaultScale={1} 
                                              defaultPositionX={0} 
                                              defaultPositionY={0}
                                              pan={{ disabled: true }} >
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    {/* <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} /> */}
                                                  </div>
                                                  <TransformComponent>
                                                    <div key={index}>
                                                      <canvas
                                                        ref={this.canvasRef}
                                                        width={imgWidth}
                                                        height={imgHeight}
                                                        onMouseDown={this.handleMouseDown}
                                                        onMouseMove={this.handleMouseMove}
                                                        onMouseUp={this.handleMouseUp}
                                                      />
                                                      <Typography.Text>{img.desc}</Typography.Text>
                                                    </div>
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            </Spin>
                                          </Modal>
                                        </div>
                                      </div>
                                    ))}
                              </Carousel>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="Front of neck" key="frontOfNeck">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Quarter " +
                            quatar +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageOhsu()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                        <div>
                                          <Modal
                                            centered
                                            width={450}
                                            className="photo-zoom-popup"
                                            title={<><span className="patid">{patientData.patientId}</span>{" - Week " + quatar + " " + this.state.sectionPart}</>}
                                            visible={isModalVisible}
                                            onCancel={this.handleCancel}
                                            footer={null}
                                          >
                                            <p style={{marginBottom: "5px", marginTop: "-15px"}}>To add a black box to a specific part of the image click and drag the mouse diagonally over the area you want to hide.</p>
                                            <div className="image-edit">
                                              <Button onClick={this.resetImage}>Reset Image</Button>
                                              <Button onClick={this.saveImage}>Save Image</Button>
                                              <Button onClick={this.deleteImage}>Delete Image</Button>
                                            </div>
                                            <Spin spinning={isImageLoading} tip="Loading image...">
                                            <TransformWrapper 
                                              defaultScale={1} 
                                              defaultPositionX={0} 
                                              defaultPositionY={0}
                                              pan={{ disabled: true }} >
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    {/* <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} /> */}
                                                  </div>
                                                  <TransformComponent>
                                                    <div key={index}>
                                                      <canvas
                                                        ref={this.canvasRef}
                                                        width={imgWidth}
                                                        height={imgHeight}
                                                        onMouseDown={this.handleMouseDown}
                                                        onMouseMove={this.handleMouseMove}
                                                        onMouseUp={this.handleMouseUp}
                                                      />
                                                      <Typography.Text>{img.desc}</Typography.Text>
                                                    </div>
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            </Spin>
                                          </Modal>
                                        </div>
                                      </div>
                                    ))}
                              </Carousel>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="Additional Area for Rash" key="optionalImages">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Quarter " +
                            quatar +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageOhsu()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                        <div>
                                          <Modal
                                            centered
                                            width={450}
                                            className="photo-zoom-popup"
                                            title={<><span className="patid">{patientData.patientId}</span>{" - Week " + quatar + " " + this.state.sectionPart}</>}
                                            visible={isModalVisible}
                                            onCancel={this.handleCancel}
                                            footer={null}
                                          >
                                            <p style={{marginBottom: "5px", marginTop: "-15px"}}>To add a black box to a specific part of the image click and drag the mouse diagonally over the area you want to hide.</p>
                                            <div className="image-edit">
                                              <Button onClick={this.resetImage}>Reset Image</Button>
                                              <Button onClick={this.saveImage}>Save Image</Button>
                                              <Button onClick={this.deleteImage}>Delete Image</Button>
                                            </div>
                                            <Spin spinning={isImageLoading} tip="Loading image...">
                                            <TransformWrapper 
                                              defaultScale={1} 
                                              defaultPositionX={0} 
                                              defaultPositionY={0}
                                              pan={{ disabled: true }} >
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    {/* <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} /> */}
                                                  </div>
                                                  <TransformComponent>
                                                    <div key={index}>
                                                      <canvas
                                                        ref={this.canvasRef}
                                                        width={imgWidth}
                                                        height={imgHeight}
                                                        onMouseDown={this.handleMouseDown}
                                                        onMouseMove={this.handleMouseMove}
                                                        onMouseUp={this.handleMouseUp}
                                                      />
                                                      <Typography.Text>{img.desc}</Typography.Text>
                                                    </div>
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            </Spin>
                                          </Modal>
                                        </div>
                                      </div>
                                    ))}
                              </Carousel>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="All Images" key="allImages">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Quarter " +
                            quatar +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                        >
                          {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageOhsu()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          }
                          <div className="images-container">
                            <Carousel
                              arrows={true}
                              prevArrow={<LeftOutlined />}
                              nextArrow={<RightOutlined />}
                              className="images-slider"
                              slidesPerRow={3}
                              swipeToSlide={true}
                              touchThreshold={20}
                              focusOnSelect={true}
                              centerMode={true}
                              ref={(carousel) => (this.nav = carousel)}
                              draggable={true}
                              autoplay={false}
                              vertical
                              effect="fade"
                            >
                              {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                        <div>
                                          <Modal
                                            centered
                                            width={450}
                                            className="photo-zoom-popup"
                                            title={<><span className="patid">{patientData.patientId}</span>{" - Week " + quatar + " " + this.state.sectionPart}</>}
                                            visible={isModalVisible}
                                            onCancel={this.handleCancel}
                                            footer={null}
                                          >
                                            <p style={{marginBottom: "5px", marginTop: "-15px"}}>To add a black box to a specific part of the image click and drag the mouse diagonally over the area you want to hide.</p>
                                            <div className="image-edit">
                                              <Button onClick={this.resetImage}>Reset Image</Button>
                                              <Button onClick={this.saveImage}>Save Image</Button>
                                              <Button onClick={this.deleteImage}>Delete Image</Button>
                                            </div>
                                            <Spin spinning={isImageLoading} tip="Loading image...">
                                            <TransformWrapper 
                                              defaultScale={1} 
                                              defaultPositionX={0} 
                                              defaultPositionY={0}
                                              pan={{ disabled: true }} >
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    {/* <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} /> */}
                                                  </div>
                                                  <TransformComponent>
                                                    <div key={index}>
                                                      <canvas
                                                        ref={this.canvasRef}
                                                        width={imgWidth}
                                                        height={imgHeight}
                                                        onMouseDown={this.handleMouseDown}
                                                        onMouseMove={this.handleMouseMove}
                                                        onMouseUp={this.handleMouseUp}
                                                      />
                                                      <Typography.Text>{img.desc}</Typography.Text>
                                                    </div>
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            </Spin>
                                          </Modal>
                                        </div>
                                      </div>
                                    ))}
                            </Carousel>

                            {/* Modal for displaying the selected image */}
                            
                          </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                </Tabs>
                // VERTICAL TAB FOR STUDY 3 END
                :
                <Tabs
                  onChange={this.handleSelectNih}
                  tabPosition="left"
                  className="vertical-data-tabs parade-photos">

                  <TabPane tab="Full Body" key="FULL_BODY" type="card">
                    <Spin spinning={loading}>

                      {!this.state.loading && (
                        
                        <Card
                          title={
                            "Month " +
                            monthNumber +
                            " " +
                            this.state.sectionPart
                          }
                          // extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                          extra={<div className="uplod-downlod-div">
                                  <Tooltip title="Upload Image">
                                    <Button 
                                      className="upload-photo"
                                      onClick={this.photoUpload}
                                      icon={<UploadOutlined />}>
                                    </Button>
                                  </Tooltip>
                                  {/* <Button 
                                    className="upload-photo"
                                    onClick={this.photoUpload}
                                  >
                                    Upload Photo
                                  </Button> */}
                                {photoGallery && photoGallery.length > 0 ? 
                                  <Tooltip title="Download all Images">
                                    <Button 
                                      onClick={() => this.downloadImageNih()} 
                                      className="leftSpace download-button"
                                      icon={<DownloadOutlined/>}
                                      ></Button>
                                  </Tooltip> :
                                      ""}
                          </div>}
                        >
                          {/* {photoGallery && photoGallery.length > 0 ?
                            <Row align="bottom" type="flex" gutter={16} className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          } */}
                            <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  width={450}
                                  className="photo-zoom-popup"
                                  // title={this.state.image.desc}
                                  title={"Month " +
                                    monthNumber +
                                    " " +
                                    this.state.sectionPart}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                        photoGalleryByWeek.map((img, index) => (
                                          <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={img.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                      style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                          </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                        </Card>
                       )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="Upper Body" key="UPPER_BODY">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Month " +
                            monthNumber +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                          extra={<div className="uplod-downlod-div">
                                    <Tooltip title="Upload Image">
                                      <Button 
                                        className="upload-photo"
                                        onClick={this.photoUpload}
                                        icon={<UploadOutlined />}>
                                      </Button>
                                    </Tooltip>
                                    {/* <Button 
                                      className="upload-photo"
                                      onClick={this.photoUpload}
                                    >
                                      Upload Photo
                                    </Button> */}
                                  {photoGallery && photoGallery.length > 0 ? 
                                    <Tooltip title="Download all Images">
                                      <Button 
                                        onClick={() => this.downloadImageNih()} 
                                        className="leftSpace download-button"
                                        icon={<DownloadOutlined/>}
                                        ></Button>
                                    </Tooltip> :
                                        ""}
                            </div>}
                        >
                          {/* {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          } */}
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  width={450}
                                  className="photo-zoom-popup"
                                  // title={this.state.image.desc}
                                  title={"Month " +
                                    monthNumber +
                                    " " +
                                    this.state.sectionPart}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                        photoGalleryByWeek.map((img, index) => (
                                          <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={img.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                      style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                          </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="Lower Body" key="LOWER_BODY">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Month " +
                            monthNumber +
                            " " +
                            this.state.sectionPart
                          }
                          // extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                          extra={<div className="uplod-downlod-div">
                                    <Tooltip title="Upload Image">
                                      <Button 
                                        className="upload-photo"
                                        onClick={this.photoUpload}
                                        icon={<UploadOutlined />}>
                                      </Button>
                                    </Tooltip>
                                    {/* <Button 
                                      className="upload-photo"
                                      onClick={this.photoUpload}
                                    >
                                      Upload Photo
                                    </Button> */}
                                  {photoGallery && photoGallery.length > 0 ? 
                                    <Tooltip title="Download all Images">
                                      <Button 
                                        onClick={() => this.downloadImageNih()} 
                                        className="leftSpace download-button"
                                        icon={<DownloadOutlined/>}
                                        ></Button>
                                    </Tooltip> :
                                        ""}
                            </div>}
                        >
                          {/* {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          } */}
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  width={450}
                                  className="photo-zoom-popup"
                                  // title={this.state.image.desc}
                                  title={"Month " +
                                    monthNumber +
                                    " " +
                                    this.state.sectionPart}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                        photoGalleryByWeek.map((img, index) => (
                                          <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={img.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                      style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                          </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                  <TabPane tab="Head and Neck" key="HEAD_AND_NECK">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Month " +
                            monthNumber +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                          extra={<div className="uplod-downlod-div">
                                    <Tooltip title="Upload Image">
                                      <Button 
                                        className="upload-photo"
                                        onClick={this.photoUpload}
                                        icon={<UploadOutlined />}>
                                      </Button>
                                    </Tooltip>
                                    {/* <Button 
                                      className="upload-photo"
                                      onClick={this.photoUpload}
                                    >
                                      Upload Photo
                                    </Button> */}
                                  {photoGallery && photoGallery.length > 0 ? 
                                    <Tooltip title="Download all Images">
                                      <Button 
                                        onClick={() => this.downloadImageNih()} 
                                        className="leftSpace download-button"
                                        icon={<DownloadOutlined/>}
                                        ></Button>
                                    </Tooltip> :
                                        ""}
                            </div>}
                        >
                          {/* {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace"> 
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          } */}
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  width={450}
                                  className="photo-zoom-popup"
                                  // title={this.state.image.desc}
                                  title={"Month " +
                                    monthNumber +
                                    " " +
                                    this.state.sectionPart}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                        photoGalleryByWeek.map((img, index) => (
                                          <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={img.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                      style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                          </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                  {
                    patientData && patientData.healthCondition === "HEALTHY_CONTROL" ? null 
                    :
                    <TabPane tab="Where disease is worst" key="STANDARDISED_CLOSE_UP">
                      <Spin spinning={loading}>
                        {!this.state.loading && (
                          <Card
                            title={
                              "Month " +
                              monthNumber +
                              " " +
                              this.state.sectionPart
                            }
                            //extra={"Date: 14/02/2021"}
                            style={{ marginBottom: "8px", padding: "0 16px" }}
                            className="week-section"
                            extra={<div className="uplod-downlod-div">
                                      <Tooltip title="Upload Image">
                                        <Button 
                                          className="upload-photo"
                                          onClick={this.photoUpload}
                                          icon={<UploadOutlined />}>
                                        </Button>
                                      </Tooltip>
                                      {/* <Button 
                                        className="upload-photo"
                                        onClick={this.photoUpload}
                                      >
                                        Upload Photo
                                      </Button> */}
                                    {photoGallery && photoGallery.length > 0 ? 
                                      <Tooltip title="Download all Images">
                                        <Button 
                                          onClick={() => this.downloadImageNih()} 
                                          className="leftSpace download-button"
                                          icon={<DownloadOutlined/>}
                                          ></Button>
                                      </Tooltip> :
                                          ""}
                              </div>}
                          >
                            {/* {photoGallery && photoGallery.length > 0 ?
                              <Row className="leftSpace">
                                <Col span={8} offset={17}>
                                  <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                                </Col>
                              </Row>
                              : ""
                            } */}
                            <div className="images-container">
                                <Carousel
                                  arrows={true}
                                  prevArrow={<LeftOutlined />}
                                  nextArrow={<RightOutlined />}
                                  className="images-slider"
                                  slidesPerRow={3}
                                  swipeToSlide={true}
                                  touchThreshold={20}
                                  focusOnSelect={true}
                                  centerMode={true}
                                  ref={(carousel) => (this.nav = carousel)}
                                  draggable={true}
                                  autoplay={false}
                                  vertical
                                  effect="fade"
                                >
                                    {photoGallery &&
                                      photoGallery.map((img, index) => (
                                        <div style={{ height: '100%' }} key={img.id}>
                                          <img
                                            src={img.url}
                                            alt={img.desc}
                                            onClick={() => this.showModal(img, index)}
                                            loading="lazy"
                                          />
                                          <Typography.Text>{img.desc}</Typography.Text>
                                        </div>
                                      ))}
                                </Carousel>
                                <div>
                                  <Modal
                                    centered
                                    width={450}
                                    className="photo-zoom-popup"
                                    // title={this.state.image.desc}
                                    title={"Month " +
                                      monthNumber +
                                      " " +
                                      this.state.sectionPart}
                                    visible={this.state.isModalVisible}
                                    onCancel={this.handleCancel}
                                    footer={null}
                                  >
                                    <Carousel
                                        arrows={true}
                                        prevArrow={<LeftOutlined />}
                                        nextArrow={<RightOutlined />}
                                        className="modal-slider images-slider"
                                        initialSlide={currentImageIndex}
                                        key={currentImageIndex}
                                      >
                                        {photoGalleryByWeek &&
                                          photoGalleryByWeek.map((img, index) => (
                                            <div key={index}>
                                              <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                                {({ zoomIn, zoomOut }) => (
                                                  <>
                                                    <div style={{ textAlign: 'center' }}>
                                                      <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                      <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                      <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                      <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                    </div>
                                                    <TransformComponent>
                                                      <img
                                                        width={400}
                                                        src={img.url}
                                                        alt={img.desc}
                                                        loading="lazy"
                                                        style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                      />
                                                    </TransformComponent>
                                                  </>
                                                )}
                                              </TransformWrapper>
                                              <Typography.Text>{img.desc}</Typography.Text>
                                            </div>
                                          ))}
                                      </Carousel>
                                  </Modal>
                                </div>
                              </div>
                          </Card>
                        )}
                      </Spin>
                    </TabPane>
                  }
                  <TabPane tab="All Images" key="ALL">
                    <Spin spinning={loading}>
                      {!this.state.loading && (
                        <Card
                          title={
                            "Month " +
                            monthNumber +
                            " " +
                            this.state.sectionPart
                          }
                          //extra={"Date: 14/02/2021"}
                          style={{ marginBottom: "8px", padding: "0 16px" }}
                          className="week-section"
                          extra={<div className="uplod-downlod-div">
                                    <Tooltip title="Upload Image">
                                      <Button 
                                        className="upload-photo"
                                        onClick={this.photoUpload}
                                        icon={<UploadOutlined />}>
                                      </Button>
                                    </Tooltip>
                                    {/* <Button 
                                      className="upload-photo"
                                      onClick={this.photoUpload}
                                    >
                                      Upload Photo
                                    </Button> */}
                                  {photoGallery && photoGallery.length > 0 ? 
                                    <Tooltip title="Download all Images">
                                      <Button 
                                        onClick={() => this.downloadImageNih()} 
                                        className="leftSpace download-button"
                                        icon={<DownloadOutlined/>}
                                        ></Button>
                                    </Tooltip> :
                                        ""}
                            </div>}
                        >
                          {/* {photoGallery && photoGallery.length > 0 ?
                            <Row className="leftSpace">
                              <Col span={8} offset={17}>
                                <Button onClick={() => this.downloadImageWeek()} className="leftSpace">Download All Images</Button>
                              </Col>
                            </Row>
                            : ""
                          } */}
                          <div className="images-container">
                              <Carousel
                                arrows={true}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className="images-slider"
                                slidesPerRow={3}
                                swipeToSlide={true}
                                touchThreshold={20}
                                focusOnSelect={true}
                                centerMode={true}
                                ref={(carousel) => (this.nav = carousel)}
                                draggable={true}
                                autoplay={false}
                                vertical
                                effect="fade"
                              >
                                  {photoGallery &&
                                    photoGallery.map((img, index) => (
                                      <div style={{ height: '100%' }} key={img.id}>
                                        <img
                                          src={img.url}
                                          alt={img.desc}
                                          onClick={() => this.showModal(img, index)}
                                          loading="lazy"
                                        />
                                        <Typography.Text>{img.desc}</Typography.Text>
                                      </div>
                                    ))}
                              </Carousel>
                              <div>
                                <Modal
                                  centered
                                  width={450}
                                  className="photo-zoom-popup"
                                  // title={this.state.image.desc}
                                  title={"Month " +
                                    monthNumber +
                                    " " +
                                    this.state.sectionPart}
                                  visible={this.state.isModalVisible}
                                  onCancel={this.handleCancel}
                                  footer={null}
                                >
                                  <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {photoGalleryByWeek &&
                                        photoGalleryByWeek.map((img, index) => (
                                          <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                              {({ zoomIn, zoomOut }) => (
                                                <>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                  </div>
                                                  <TransformComponent>
                                                    <img
                                                      width={400}
                                                      src={img.url}
                                                      alt={img.desc}
                                                      loading="lazy"
                                                      style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                  </TransformComponent>
                                                </>
                                              )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                          </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                              </div>
                            </div>
                        </Card>
                      )}
                    </Spin>
                  </TabPane>
                </Tabs>
                }
                {
                  studyId == "3" ? "" :
                  <ul className="link-list-box" style={{ display: "block" }}>
                  {
                    studyId == 1 ?
                    <li>
                      <div class="link-box">
                        <ChatIcon />
                        <h4>Chat</h4>
                      </div>
                    </li>
                    :
                    ""
                  }
                  <li>
                    <div
                      onClick={() => this.goToEASiScoring()}
                      className={
                        selectedCol === "survey"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <ServeyIcon />
                      <h4>Surveys &amp; Scores</h4>
                    </div>
                  </li>
                  <li>
                    <div onClick={() => this.goToDiary()} className="link-box">
                      <DairyIcon />
                      <h4>Diary</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      className={
                        selectedCol === "gallery"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <PhotogalleryIcon />
                      <h4>Photo Gallery</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToAdverseEvents()}
                      class="link-box"
                    >
                      <AdverseEventIcon />
                      <h4>Adverse Events</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToActivityData()}
                      class="link-box"
                    >
                      <DataIcon />
                      <h4>Activity Data</h4>
                    </div>
                  </li>
                  {
                    studyId == 1 ?
                    <li>
                      <div
                        onClick={() => this.goToStudyDrugLog("log")}
                        class="link-box"
                      >
                        <StudyDrugLogIcon />
                        <h4>Study Drug Log</h4>
                      </div>
                    </li>
                    :
                    ""
                  }
                  {
                    studyId == 1 ?
                    <li>
                      <div onClick={() => this.goToLab("lab")} class="link-box">
                        <LabIcon />
                        <h4>Lab</h4>
                      </div>
                    </li>
                    :
                    "" 
                  }
                  {
                    studyId == 4 && (patientData && patientData.healthCondition !== "HEALTHY_CONTROL") ?
                    <li>
                      <div
                        onClick={() => this.goToFlares()}
                        class="link-box"
                      >
                        <AuditOutlined style={{fontSize: "35px"}}/>
                        <h4>Flares</h4>
                      </div>
                    </li>
                    : ""
                  }
                  {
                    studyId === "4" ?
                    <li>
                      <div
                        onClick={() => this.goToNewMed()}
                        class="link-box"
                      >
                        <MedicationIcon/>
                        <h4>New Medication</h4>
                      </div>
                    </li>
                    : ""
                  }
                </ul>
                }
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    photoGalleryByWeek: state.patient.photoGalleryByWeek,
    nihPhotoGallery: state.patient.nihPhotoGallery,
    photoGallery: state.patient.photoGallery,
    patientData: state.patient.patientData,
    easiScores: state.patient.easiScores,
    janssenPhotoGalleryNew: state.patient.janssenPhotoGalleryNew,
    janssenPhotoGalleryWeek: state.patient.janssenPhotoGalleryWeek,
    uploadPhoto: state.patient.uploadPhoto,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...PatientDiaryActions, ...StudyActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    PhotoGallery
  )
);
