import Login from "../components/login/login";
import ForgotPassword from "../components/forgot-password/forgot-password.jsx";
import ResetPassword from "../components/reset-password/reset-password.jsx";
import Dashboard from "../components/dashboard/dashboard";
import UserList from "../components/admin/manageUser/list";
import AddUser from "../components/admin/manageUser/add";
import EditUser from "../components/admin/manageUser/edit-user";
import ActivityLogs from "../components/admin/manageUser/audit-logs";
import PatientTabs from "../components/patient/patient-dashboard";
import ViewPatientProgress from "../components/patient/index";
import ScreeningQuestions from "../components/patient/screening-questions";
import SurveyReview from "../components/survey/surveyReview";
import Appointments from "../components/appointment/calender";
import Chat from "../components/chat/chat";
import ChatList from "../components/chat/list-chat";
import PatientDiary from "../components/patientDiary/patient-diary";
import Tickets from "../components/tickets/tickets";
import Profile from "../components/profile/profile";
import Notifications from "../components/notifications/notifications";
import ListStudies from "../components/study/list-studies";
import PhotoGallery from "../components/photoGallery/photo-gallery";
import AdverseEvents from "../components/adverse-events/adverse-events";
import PISignOff from "../components/adverse-events/piSignOff";
import AdverseEventDetail from "../components/adverse-events/adverse-event-detail"
import ListAdverseEvents from "../components/adverse-events/all-adverse-events";
import PatientActivity from "../components/activity/patient-activity";
import EASILowerExt from "../components/easiTotalReview//easiLowerExt";
import EASITrunk from "../components/easiTotalReview/easiTrunk";
import EASITotal from "../components/easiTotalReview/easiTotal";
import EASIHead from "../components/easiTotalReview/easihead";
import Medication from "../components/medication/medication";
import EASIUpper from "../components/easiTotalReview/easiUpperExt";
import GlobalAssessment from "../components/easiTotalReview/globalAssessment"
import StudyDrugLog from "../components/study-drug-log/study-drug-log";
import EventLogs from "../components/adverse-events/event-logs"
import MedicalHistory from "../components/medical-history/medical-history";
import Lab from "../components/lab/lab"
import TermsAndConditions from "../components/terms-condition/terms-and-conditions";
import PrivacyPolicy from "../components/terms-condition/privacy-policy";
import PrivacyPolicyNih from "../components/terms-condition/privacy-policy-nih";
import TermsAndConditionsNih from "../components/terms-condition/terms-and-conditions-nih";
import Flares from "../components/flares-task/flares";
import FlaresTask from "../components/flares-task/flares-tasks";
import FlarePhotographs from "../components/flares-task/flares-photographs";
import FlaresDiary from "../components/flares-task/flares-diary";
import FlaresMedication from "../components/flares-task/flares-medication";
import NewRashPhotoGallery from "../components/photoGallery/new-rash";
import NihMedication from "../components/medication/nih-mediction"
import NotFound from "../components/404-not-found/404-not-found";

export default {
  routes: [
    {
      path: "/",
      component: Login,
      exact: true,
    },
    {
      path: "/login",
      component: Login,
      exact: false,
    },
    {
      path: "/forgot-password",
      component: ForgotPassword,
      exact: false,
    },
    {
      path: "/reset-password",
      component: ResetPassword,
      exact: false,
    },
    {
      path: "/janssen-study/terms-and-conditions",
      component: TermsAndConditions,
      exact: false,
    },
    {
      path: "/janssen-study/privacy-policy",
      component: PrivacyPolicy,
      exact: false,
    },
    {
      path: "/nih-study/terms-and-conditions",
      component: TermsAndConditions,
      exact: false,
    },
    {
      path: "/nih-study/privacy-policy",
      component: PrivacyPolicy,
      exact: false,
    },
  ],
  privateRoutes: [
    {
      path: "/dashboard/",
      component: Dashboard,
      exact: true,
    },
    {
      path: "/user-list/",
      component: UserList,
      exact: true,
      roles: ["ADMIN","SUPERADMIN"],
    },
    {
      path: "/add-user",
      component: AddUser,
      exact: true,
    },
    {
      path: "/edit-user/:userId",
      component: EditUser,
      exact: true,
    },
    {
      path: "/audit-logs/:id",
      component: ActivityLogs,
      exact: true,
    },
    {
      path: "/patients/:key",
      component: PatientTabs,
      exact: true,
    },
    {
      path: "/patient-progress/:id",
      component: ViewPatientProgress,
      exact: true,
    },
    {
      path: "/screening-questions/:type/:id",
      component: ScreeningQuestions,
      exact: true,
    },
    {
      path: "/medical-history/:id",
      component: MedicalHistory,
      exact: true,
    },
    {
      path: "/lab/:id",
      component: Lab,
      exact: true,
    },
    {
      path: "/appointments",
      component: Appointments,
      exact: true,
    },
    {
      path: "/tickets",
      component: Tickets,
      exact: true,
    },
    {
      path: "/profile",
      component: Profile,
      exact: true,
    },
    {
      path: "/notifications",
      component: Notifications,
      exact: true,
    },
    {
      path: "/studies",
      component: ListStudies,
      exact: true,
    },
    {
      path: "/chat/:id",
      component: Chat,
      exact: true,
    },
    {
      path: "/chatList/",
      component: ChatList,
      exact: true,
    },
    {
      path: "/patient-diary/:id",
      component: PatientDiary,
      exact: true,
    },
    {
      path: "/photo-gallery/:id",
      component: PhotoGallery,
      exact: true,
    },
    {
      path: "/EASI-lowerExt/:id",
      component: EASILowerExt,
      exact: true,
    },
    {
      path: "/adverse-events/:id",
      component: AdverseEvents,
      exact: true,
    },
    {
      path: "/adverse-events",
      component: ListAdverseEvents,
      exact: true,
    },
    {
      path: "/event-details/:id/:patientId",
      component: AdverseEventDetail,
      exact: true,
    },
    {
      path: "/activity-data/:id",
      component: PatientActivity,
    },
    {
      path: "/EASI-total/:id",
      component: EASITotal,
    },
    {
      path: "/globalAssessment/:id",
      component: GlobalAssessment,
    },
    {
      path: "/EASI-head/:id",
      component: EASIHead,
    },
    {
      path: "/EASI-upperExt/:id",
      component: EASIUpper,
    },
    {
      path: "/EASI-lowerExt/:id",
      component: EASILowerExt,
    },
    {
      path: "/EASI-trunk/:id",
      component: EASITrunk,
    },
    {
      path: "/medication/:id",
      component: Medication,
    },
    {
      path: "/surveyReview/:id/:taskId",
      component: SurveyReview,
    },
    {
      path: "/adverse-event-signOff/:userId/:adverseId",
      component: PISignOff,
    },
    {
      path: "/study-drug-log/:id",
      component: StudyDrugLog,
      exact: true,
    },
    {
      path: "/event-logs/:eventId",
      component: EventLogs,
    },
    {
      path: "/flares/:id",
      component: Flares,
    },
    // {
    //   path: "/flare-tasks/:id/:taskId/:taskName",
    //   component: FlaresTask,
    // },
    {
      path: "/flare-tasks/:id/:taskId",
      component: FlaresTask,
    },
    {
      path: "/flare-photographs/:id/:date",
      component: FlarePhotographs,
    },
    {
      path: "/flare-diary/:id/:date",
      component: FlaresDiary,
      exact: true,
    },
    {
      path: "/flares-medication/:id/:taskId",
      component: FlaresMedication,
    },
    {
      path: "/new-rash-photogallery/:id",
      component: NewRashPhotoGallery,
      exact: true,
    },
    {
      path: "/new-medication/:id",
      component: NihMedication,
    },
    {
      path: "*",
      component: NotFound,
      exact: true,
    },  
 
  ]
};
