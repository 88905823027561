import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";
import * as PatientDiaryActions from "../../redux/actions/patient-diary-actions";
import "react-h5-audio-player/lib/styles.css";
import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Tabs,
  Modal,
  Carousel,
  Button,
  Form,
  Input,
  PageHeader,
  Upload,
  Tooltip
} from "antd";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  UploadOutlined,
  DownloadOutlined,
  AuditOutlined
} from "@ant-design/icons";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import { StudyDrugLogSvg } from "../shared/svg/studydruglog.jsx";
import { LabSvg } from "../shared/svg/labicon";
import photoGallery from "../photoGallery/photo-gallery.jsx";
import { MedicationIconSvg } from "../shared/svg/medication";

const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const StudyDrugLogIcon = (props) => (
  <Icon component={StudyDrugLogSvg} {...props} />
);
const MedicationIcon = (props) => (
  <Icon component={MedicationIconSvg} {...props} />
);

const { TabPane } = Tabs;
const ButtonGroup = Button.Group;

class FlarePhotographs extends Component {
  formRef = React.createRef();
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      image: {},
      isModalVisible: false,
      submitted: false,
      selectedCol: "gallery",
      bodypartType: "HEAD",
      bodypartTypeByWeek: "FULL_BODY",
      partTypeNih: "FULL_BODY",
      partType: "Head Image",
      partTypeByWeek: "Full Body Image",
      month: 1,
      week: 1,
      monthnih: 1,
      photoGallery: [],
      flarePhoto: [],
      flareImages: undefined,
      easiScores: undefined,
      selectedMonth: 1,
      selectedWeek: 1,
      selectedMonthNih: 1,
      section: "section1",
      sectionWeek: "section1",
      sectionPart: "Section 1 images",
      imageName: "rightElbowCrease",
      quatar: 1,
      paradeUser: undefined,
      currentImageIndex: 0,
      visibleModal: false,
      fileList: [],
      imageBase64: '',
      selectedFile: null,
      rotationAngles: (props.flareImages && props.flareImages.length) ? props.flareImages.map(() => 0) : [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    try {
      let studyId = localStorage.getItem("studyId");
      this.props
        .getStudyById(studyId)
        .then((resp) => {
          this.setState({
            //  loading: false,
            data: resp.data,
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
        let patientId = this.props.match.params.id;
        await this.props.getPatientByIdNih(patientId);
     
      if (
        this.props.patientData &&
        this.props.patientData.length > 0 &&
        this.props.patientData[0]
      ) {
        //this.setState({ loading: false })
      }
      let date = this.props.match.params.date;
      let photoData = await this.props.getAllFlareImages(patientId, date);
      this.setState({photoGallery: photoData})
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    this.setState({ loading: false });
       
  }
  componentWillUnmount(nextProps) {
    // Set a flag to indicate that the component is unmounted
    this._isMounted = false;
  }

  async componentDidUpdate(prevProps, prevState) {

    // Check if the relevant state or props have changed
    if (this.state.photoGallery !== prevState.photoGallery) {
      // Perform any actions or side-effects here
    }
    if (prevProps.flareImages !== this.props.flareImages) {
      this.setState({
        rotationAngles: this.props.flareImages && this.props.flareImages.length ? this.props.flareImages.map(() => 0) : [],
      });
    }
    // if (this.props.flareImages != prevProps.flareImages)
  }

  async componentWillReceiveProps(nextProps) {
    let studyId = localStorage.getItem("studyId")
    if (studyId == 1) {
      if (this.props.easiScores !== nextProps.easiScores) {
        this.setState({ photoGallery: nextProps.photoGallery });
        this.setState({ easiScores: nextProps.easiScores });
        this.setState({ loading: false });
      }
    } else {
      if (this.props.easiScores !== nextProps.easiScores) {
        this.setState({ photoGallery: nextProps.flareImages });
        this.setState({ easiScores: nextProps.easiScores });
        this.setState({ loading: false });
      }
    }
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };

  goToDiary = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  showModal = (img, index) => {
    this.setState({
      isModalVisible: true,
      currentImageIndex: index,
      image: img,
    });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false, image: {}, visibleModal: false, fileList: [], imageBase64: '', selectedFile: null});
  };


  downloadImageOhsu = async () => {
    console.log('testt');
    notifyUser("Download images in progress, it might take a little while so be patient..", "loading");
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let imageName = this.state.imageName;
    let quatar = this.state.quatar;
    var response = await this.props.downloadImagesOhsu("allImages", studyId, quatar, patientId);
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet_stream' }));
    a.download = "images.zip";

    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
    let deleteZip = await this.props.deleteZipFile();
    notifyUser("Images downloaded successfully", "success");      
  };

 rotateImage = (index, direction) => {
    this.setState((prevState) => {
      const newAngles = [...prevState.rotationAngles];
      newAngles[index] += direction === 'left' ? -90 : 90;
      return { rotationAngles: newAngles };
    });
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  goToNewMed = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/new-medication/" + patientId);
  }

  render() {
    const {
      visibleModal,
      fileList,
      submitted,
      selectedCol,
      photoGallery,
      easiScores,
      selectedMonth,
      selectedMonthNih,
      loading,
      selectedWeek,
      paradeUser,
      currentImageIndex,
      isModalVisible,
      image,
      rotationAngles,
      monthnih,
    } = this.state;

    let monthnu = this.state.monthnih;

    const { flareImages} = this.props;

    let week = this.state.week;
    let weekNumber;

    const studyId = localStorage.getItem("studyId");

    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }

    // Patient Start End time
    const notificationEndDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate;
    const notificationTimezone = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationTimezone;
    const endDateInTimezone = moment.tz(notificationEndDate, notificationTimezone);
    let formattedTimeEnd = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate) {
      formattedTimeEnd = endDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeEnd = "";
    }
    const formattedTimeEndData = formattedTimeEnd;

    const notificationStartDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate;
    const startDateInTimezone = moment.tz(notificationStartDate, notificationTimezone);
    let formattedTimeStart = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate) {
      formattedTimeStart = startDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeStart = "";
    }
    const formattedTimeStartData = formattedTimeStart;
    // Patient Start End time end

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">

            <PageHeader
              className="site-header-title"
              title={
                this.state.data && paradeUser && studyId == "3" ?
                  this.state.data.title+" - "+paradeUser[0].patientId :
                  this.state.data && this.state.data.title
              }
              extra={[
                <Button onClick={() => this.goToPatientProgress()}>
                    Back
                  </Button> ,]}
            />
            <Row gutter={16}>
              {
                studyId === "3" ? ""
                :
                <Col xs={24} sm={12} md={6} xl={6}>
                  <div className="white-box user-section">
                    <div className="user-profile">
                      <Link to={"/patient-progress/" + this.props.match.params.id} >
                        <Avatar size={84}>
                          {this.props.patientData
                            ? this.props.patientData[0].firstName
                              .toUpperCase()
                              .charAt(0) +
                            this.props.patientData[0].lastName
                              .toUpperCase()
                              .charAt(0)
                            : ""}
                        </Avatar>
                      </Link>
                      <div className="profile-details">
                        <Link to={"/patient-progress/" + this.props.match.params.id} >
                          <h3>
                            {this.props.patientData
                              ? this.props.patientData[0].firstName +
                              " " +
                              this.props.patientData[0].lastName
                              : ""}
                          </h3>
                        </Link>
                      </div>
                    </div>
                    <div className="user-details">
                      <List
                        style={{ marginTop: "40px" }}
                        itemLayout="horizontal"
                        dataSource={this.props.patientData}
                        renderItem={(patient) => (
                          <>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Study:
                              </Typography.Text>{" "}
                              {patient && patient.title}
                            </List.Item>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Email:
                              </Typography.Text>{" "}
                              {patient.email}
                            </List.Item>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Date of Birth:
                              </Typography.Text>
                                {finalDate}
                            </List.Item>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Age:
                              </Typography.Text>{" "}
                              {patient.age ? patient.age + " years" : "N/A"}
                            </List.Item>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Phone Number:
                              </Typography.Text>{" "}
                              {"(" +
                                patient.phoneCode +
                                ") " +
                                patient.phoneNumber}
                            </List.Item>
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Member Status:
                              </Typography.Text>{" "}
                              {patient.userScreeningStatus.replace("_", " ")}
                            </List.Item>
                            {
                              studyId == 2 ?
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Patient's TimeZone:
                                </Typography.Text>{" "}
                                  {patient.notificationTimezone}
                              </List.Item>
                              : ""
                            }
                            {
                              studyId == 2 ?
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Notifications Time:
                                </Typography.Text>{" "}
                                  {formattedTimeStartData} {!formattedTimeStartData && !formattedTimeEndData ? "" : "to"} {formattedTimeEndData}
                              </List.Item>
                              : 
                              ""
                            }
                            <List.Item>
                              <Typography.Text style={{ color: "#006297" }}>
                                Registered Date:
                              </Typography.Text>{" "}
                              {patient.createdDate
                                ? moment(patient.createdDate)
                                  .format(dateFormathh)
                                : "N/A"}
                            </List.Item>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </Col>
              }
              

              <Col xs={24} sm={studyId == "3" ? 24 : 12} md={studyId == "3" ? 24 :18} xl={studyId == "3" ? 24 :18}>
                <Card
                    title={"Flare Photographs"}
                    // extra={"Date: 14/02/2021"}
                    style={{ marginBottom: "8px", padding: "0 16px" }}
                    className="flares-section"
                >
                    <Spin spinning={loading}>                
                        <div className="images-container">
                            <Carousel
                            arrows={true}
                            prevArrow={<LeftOutlined />}
                            nextArrow={<RightOutlined />}
                            className="images-slider"
                            slidesPerRow={3}
                            swipeToSlide={true}
                            touchThreshold={20}
                            focusOnSelect={true}
                            centerMode={true}
                            ref={(carousel) => (this.nav = carousel)}
                            draggable={true}
                            autoplay={false}
                            vertical
                            effect="fade"
                            >
                                {photoGallery &&
                                photoGallery.map((img, index) => (
                                    <div style={{ height: '100%' }} key={img.id}>
                                    <img
                                        src={img.url}
                                        alt={img.desc}
                                        onClick={() => this.showModal(img, index)}
                                        loading="lazy"
                                    />
                                    <Typography.Text>{img.desc}</Typography.Text>
                                    </div>
                                ))}
                            </Carousel>
                            <div>
                                <Modal
                                    centered
                                    width={450}
                                    className="photo-zoom-popup"
                                    // title={this.state.image.desc}
                                    title={"Flare Images"}
                                    visible={this.state.isModalVisible}
                                    onCancel={this.handleCancel}
                                    footer={null}
                                >
                                    <Carousel
                                        arrows={true}
                                        prevArrow={<LeftOutlined />}
                                        nextArrow={<RightOutlined />}
                                        className="modal-slider images-slider"
                                        initialSlide={currentImageIndex}
                                        key={currentImageIndex}
                                    >
                                        {flareImages &&
                                        flareImages.map((img, index) => (
                                            <div key={index}>
                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                                {({ zoomIn, zoomOut }) => (
                                                <>
                                                    <div style={{ textAlign: 'center' }}>
                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                    <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                    <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} />
                                                    </div>
                                                    <TransformComponent>
                                                    <img
                                                        width={400}
                                                        src={img.url}
                                                        alt={img.desc}
                                                        loading="lazy"
                                                        style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                    />
                                                    </TransformComponent>
                                                </>
                                                )}
                                            </TransformWrapper>
                                            <Typography.Text>{img.desc}</Typography.Text>
                                            </div>
                                        ))}
                                    </Carousel>
                                </Modal>
                            </div>
                        </div>
                    </Spin>
                </Card>
                {
                  studyId == "3" ? "" :
                  <ul className="link-list-box" style={{ display: "block" }}>
                  {
                    studyId == 1 ?
                    <li>
                      <div class="link-box">
                        <ChatIcon />
                        <h4>Chat</h4>
                      </div>
                    </li>
                    :
                    ""
                  }
                  <li>
                    <div
                      onClick={() => this.goToEASiScoring()}
                      className={
                        selectedCol === "survey"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <ServeyIcon />
                      <h4>Surveys &amp; Scores</h4>
                    </div>
                  </li>
                  <li>
                    <div onClick={() => this.goToDiary()} className="link-box">
                      <DairyIcon />
                      <h4>Diary</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      className={
                        selectedCol === "gallery"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <PhotogalleryIcon />
                      <h4>Photo Gallery</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToAdverseEvents()}
                      class="link-box"
                    >
                      <AdverseEventIcon />
                      <h4>Adverse Events</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToActivityData()}
                      class="link-box"
                    >
                      <DataIcon />
                      <h4>Activity Data</h4>
                    </div>
                  </li>
                  {
                    studyId == 1 ?
                    <li>
                      <div
                        onClick={() => this.goToStudyDrugLog("log")}
                        class="link-box"
                      >
                        <StudyDrugLogIcon />
                        <h4>Study Drug Log</h4>
                      </div>
                    </li>
                    :
                    ""
                  }
                  {
                    studyId == 1 ?
                    <li>
                      <div onClick={() => this.goToLab("lab")} class="link-box">
                        <LabIcon />
                        <h4>Lab</h4>
                      </div>
                    </li>
                    :
                    "" 
                  }
                  {
                    studyId == 4 ?
                    <li>
                    <div
                      onClick={() => this.goToFlares()}
                      class="link-box"
                    >
                      <AuditOutlined style={{fontSize: "35px"}}/>
                      <h4>Flares</h4>
                    </div>
                  </li>
                  : ""
                  }
                  {
                    studyId === "4" ?
                    <li>
                      <div
                        onClick={() => this.goToNewMed()}
                        class="link-box"
                      >
                        <MedicationIcon/>
                        <h4>New Medication</h4>
                      </div>
                    </li>
                    : ""
                  }
                </ul>
                }
              </Col>
              
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    flareImages: state.patient.flareImages,
    nihPhotoGallery: state.patient.nihPhotoGallery,
    photoGallery: state.patient.photoGallery,
    patientData: state.patient.patientData,
    easiScores: state.patient.easiScores,
    janssenPhotoGalleryNew: state.patient.janssenPhotoGalleryNew,
    janssenPhotoGalleryWeek: state.patient.janssenPhotoGalleryWeek,
    uploadPhoto: state.patient.uploadPhoto,
    flareImages: state.diary.flareImages
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...PatientDiaryActions, ...StudyActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    FlarePhotographs
  )
);
