import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactApexChart from "react-apexcharts";
import moment from "moment-timezone";
import * as ActivityActions from "../../redux/actions/patient-activity-actions";

const dateFormat = "Do MMM";

class DailySteps extends Component {
  constructor(props) {
    super(props);
    this.state = {sleepData:[]}
  }

 async componentDidMount() {
    let patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId");
    if (studyId === "4") {
      await this.props.getPhysicalActivitiesNih(patientId, studyId);
    } else {
      await this.props.getPhysicalActivities(patientId);
    }
    if(this.props.physicalActivity && this.props.physicalActivity.length>0){
      this.setState({sleepData:this.props.physicalActivity})
    }
  };

  render() {
  const {sleepData} = this.state;
  
    let series = [];
    let steps = [];
    let seriesData = {}
    if (sleepData) {
      sleepData.map((dailySteps, index) => {
        var obj = {};
        obj["x"] = moment(dailySteps.date).format(dateFormat);
        if (dailySteps.steps % 1 !== 0) {
          // If steps value is a decimal, format it to 2 decimal places
          obj["y"] = parseFloat(dailySteps.steps).toFixed(2);
        } else {
          // If steps value is an integer, keep it as it is
          obj["y"] = dailySteps.steps;
        }
        steps.push(obj);
      })
      series.push({ "name":"Steps","data": steps });
      seriesData = series;
    }
    const options = {
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: true
        }
      },
      xaxis: {
        labels: {
          rotate: -90,
          // rotateAlways: false,
          // hideOverlappingLabels: true,
          showDuplicates: false,
          // trim: true,
          // minHeight: undefined,
          // maxHeight: 120,
          style: {
              colors: [],
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
          },
        },
      },

      dataLabels: {
        enabled: false
      }
    };

    return (
      <div id = "chart"  >
      {seriesData ?  <ReactApexChart options={options} series={seriesData} type="area" height={350} width={"100%"} />:""}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    physicalActivity: state.activity.getPhysicalActivity,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({...ActivityActions}, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DailySteps)
);
