import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";

import * as AdverseEventActions from "../../redux/actions/adverse-event-actions";
import "react-h5-audio-player/lib/styles.css";

import {
  Layout,
  Row,
  Col,
  Spin,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Comment,
  Tooltip,
  List,
  Typography,
  PageHeader,
} from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import { MedicationIconSvg } from "../shared/svg/medication";
import Icon from "@ant-design/icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { PlusCircleFilled, MinusCircleFilled, AuditOutlined } from "@ant-design/icons";

const dateFormathh = "MM/DD/YYYY HH:mm:ss";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);

const MedicationIcon = (props) => (
  <Icon component={MedicationIconSvg} {...props} />
);

class AdverseEventDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      formKey: 0,
      isModalVisible: false,
      submitted: false,
      patientVoiceDiary: [],
      selectItemId: "",
      selectedCol: "adverseEvents",
      adverseEvent: undefined,
      imageUrl: undefined,
    };
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    var eventId = this.props.match.params.id;
    var patientId = this.props.match.params.patientId;
    await this.props.reviewAdverseEvents(studyId, eventId, patientId);
    this.setState({
      selectItemId: eventId,
      adverseEvent: this.props.adverseEventDetail,
      patientId: patientId,
    });
    await this.props.getDoctorCommentsOnAdverseEvents(eventId);
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  showModal = (img) => {
    this.setState({ image: img, isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  addDoctorNotesOnAdverseEvents = async (values) => {
    this.setState({ formKey: (this.state.formKey || 0) + 1 });
    let self = this;
    if (self.state.eventId === "" || self.state.eventId === null) {
      notifyUser("Please select any event", "error");
    } else {
      let data = {
        doctorNote: values.doctorNotes,
        // patientId: this.props.match.params.id,
        eventId: this.props.match.params.id,
      };

      this.setState({ submitted: true });
      await self.props.addDoctorNotesOnAdverseEvents(data).then((response) => {
        if (response.error && response.error !== "") {
          notifyUser(response.error.message, "error");
          self.setState({ submitted: false });
        } else {
          if (response.data && response.data !== "") {
            notifyUser("Doctor note added Successfully", "success");
            self.setState({ submitted: false });
          }
        }
      });
    }
  };
  goToPISignOff = () => {
    var eventId = this.props.match.params.id;
    var patientId = this.props.match.params.patientId;
    this.props.history.push(
      "/adverse-event-signOff/" + patientId + "/" + eventId
    );
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };
  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-progress/" + patientId);
  };

  goToDashboard = () => {
    this.props.history.push("/dashboard");
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  goToNewMed = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/new-medication/" + patientId);
  }

  render() {
    const { submitted, selectItemId, selectedCol, adverseEvent, imageUrl } =
      this.state;
    const studyId  = localStorage.getItem("studyId");
    const patientData = this.props.patientData && this.props.patientData[0];

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <PageHeader
              className="site-header-title"
              title={
                studyId === "2" ? 
                "JANSSEN SKINTRACKER STUDY"
                  : studyId === "1" ? "Eczema Tracking Study" 
                  : studyId === "4" ? "NIH SkinTracker"
                  : "SkinTracker-PARADE"
              }
            ></PageHeader>
            <Spin spinning={submitted}>
              {!this.state.loading && (
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} xl={24}>
                    <Card
                      title={"Adverse Events"}
                      extra={
                        <Button onClick={() => this.goToDashboard()}>
                          Back
                        </Button>
                      }
                      style={{ marginBottom: "8px", padding: "0 16px" }}
                      className="patient-dairy-section"
                    >
                      <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} xl={24}>
                          {/* Activity Content Section Start */}
                          <div className="dairy-content-section">
                            {adverseEvent ? (
                              <Form
                                layout="vertical"
                                key={this.state.formKey}
                                onFinish={this.addDoctorNotesOnAdverseEvents}
                              >
                                <List
                                  itemLayout="horizontal"
                                  className="screening-ques"
                                  dataSource={this.props.adverseEventDetail}
                                  renderItem={(quesAnsData, index) => (
                                    <>
                                      <List.Item>
                                        <List.Item.Meta
                                          title={
                                            <span>
                                              <b>{"Q" + (index + 1) + ". "}</b>{" "}
                                              {quesAnsData.question}
                                            </span>
                                          }
                                          description={
                                            <span>
                                              <b>{"A" + (index + 1) + ". "}</b>{" "}
                                              {quesAnsData.answer}
                                            </span>
                                          }
                                        />
                                        <></>
                                      </List.Item>
                                    </>
                                  )}
                                />
                                {
                                  this.props.adversePhotoUrl && this.props.adversePhotoUrl.length >0 ?
                                  <List
                                  grid={{ gutter: 8, column: 5 }}
                                  itemLayout="verticle"
                                  dataSource={this.props.adversePhotoUrl}
                                  renderItem={(img) => (
                                    <div>
                                      <List.Item>
                                        <img
                                          onClick={() => this.showModal(img)}
                                          width={130}
                                          src={img.url}
                                        />
                                        <Typography.Text>
                                          {img.desc}
                                        </Typography.Text>
                                      </List.Item>

                                      <Modal
                                        centered
                                        width={450}
                                        className="photo-zoom-popup"
                                        visible={this.state.isModalVisible}
                                        onCancel={this.handleCancel}
                                        footer={[<></>]}
                                      >
                                        <TransformWrapper
                                          defaultScale={1}
                                          defaultPositionX={1}
                                          defaultPositionY={1}
                                        >
                                          {({ zoomIn, zoomOut }) => (
                                            <>
                                              <PlusCircleFilled
                                                size={30}
                                                onClick={zoomIn}
                                              />
                                              <MinusCircleFilled
                                                size={20}
                                                onClick={zoomOut}
                                              />
                                              <TransformComponent>
                                                <img
                                                  width={400}
                                                  src={this.state.image.url}
                                                  alt={img.desc}
                                                />
                                              </TransformComponent>
                                            </>
                                          )}
                                        </TransformWrapper>
                                      </Modal>
                                    </div>
                                  )}
                                /> : ""

                                }

                                {this.props.doctorComments ? (
                                  <div className="previous-comment-section">
                                    <Form.Item label="Previous Comments">
                                      <div
                                        style={{
                                          maxHeight: "300px",
                                          overflow: "auto",
                                        }}
                                      >
                                        {this.props.doctorComments.map(
                                          (comment) => {
                                            return (
                                              <div>
                                                <Comment
                                                  author={comment.doctorName}
                                                  content={comment.doctorNote}
                                                  datetime={
                                                    <Tooltip>
                                                      {moment(
                                                        comment.noteDate
                                                      ).format(dateFormathh)}
                                                    </Tooltip>
                                                  }
                                                />
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </Form.Item>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <Form.Item
                                  name="doctorNotes"
                                  label="Doctor Notes"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please add comments!",
                                      whitespace: true,
                                    },
                                  ]}
                                >
                                  <Input.TextArea placeholder="Add comment..." />
                                </Form.Item>

                                <Form.Item className="btn-form-container">
                                  <Button type="primary" htmlType="submit">
                                    Submit
                                  </Button>
                                  <Button
                                    type="primary"
                                    onClick={() => this.goToPISignOff()}
                                  >
                                    PI SignOff
                                  </Button>
                                </Form.Item>
                              </Form>
                            ) : (
                              ""
                            )}
                          </div>

                          {/* /Activity Content Section End */}
                        </Col>
                      </Row>
                    </Card>

                    <ul className="link-list-box" style={{ display: "block" }}>
                      <li>
                        <div onClick={() => this.goToChat()} class="link-box">
                          <ChatIcon />
                          <h4>Chat</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToEASiScoring()}
                          class="link-box"
                        >
                          <ServeyIcon />
                          <h4>Surveys &amp; Scores</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToDiary("diary")}
                          className={
                            selectedCol === "diary"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <DairyIcon />
                          <h4>Diary</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToPhotoGallery("gallery")}
                          className={
                            selectedCol === "gallery"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <PhotogalleryIcon />
                          <h4>Photo Gallery </h4>
                        </div>
                      </li>
                      <li>
                        <div
                          className={
                            selectedCol === "adverseEvents"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <AdverseEventIcon />
                          <h4>Adverse Events</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToActivityData("activity")}
                          class="link-box"
                        >
                          <DataIcon />
                          <h4>Activity Data</h4>
                        </div>
                      </li>
                      {
                        studyId == 4 && (patientData && patientData.healthCondition !== "HEALTHY_CONTROL") ?
                        <li>
                          <div
                            onClick={() => this.goToFlares()}
                            class="link-box"
                          >
                            <AuditOutlined style={{fontSize: "35px"}}/>
                            <h4>Flares</h4>
                          </div>
                        </li>
                        : ""
                      }
                      {
                        studyId === "4" ?
                        <li>
                          <div
                            onClick={() => this.goToNewMed()}
                            class="link-box"
                          >
                            <MedicationIcon/>
                            <h4>New Medication</h4>
                          </div>
                        </li>
                        : ""
                      }
                    </ul>
                  </Col>
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    doctorComments: state.adverseEvents.doctorNotesList,
    adverseEventDetail: state.adverseEvents.adverseEventDetail,
    adversePhotoUrl: state.adverseEvents.adversePhotoUrl,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...StudyActions, ...AdverseEventActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    AdverseEventDetail
  )
);
